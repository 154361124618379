import styled from 'styled-components';
import { Menu } from '@headlessui/react'
import {Link} from "react-router-dom";

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 1.25rem;
 
`

export const NavLeft = styled.div`
  display: flex;
`

export const LogoImage = styled.img`
  height: 25px;
  cursor: pointer;
  z-index: 2;
`

export const NavLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`


export const NavRight = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
  
  .relative{
    position: relative;
  }
`


export const AdminText = styled.div`
  line-height: .8;
`;

export const MenuButton = styled(Menu.Button)`
  margin: -1.5rem;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  text-transform: none;
  border: 0 solid #e5e7eb;
  font-family: inherit;
  cursor: pointer;
`;

export const ProfilePlaceholder = styled.div`
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
  align-items: center;
  color: #ffffff;
  background-color: #000000;
  border-radius: 9999px;
  text-transform: uppercase;
`;

export const ProfileImage = styled.img`
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
  align-items: center;
  color: #ffffff;
  background-color: #000000;
  border-radius: 9999px;
  text-transform: uppercase;
`;

export const UserName = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    align-items: start;
    flex-direction: column;
  }

  .full-name {
    margin-left: 1rem;
    font-size: 16px;
    font-weight: 600;
    color: #111827;
  }

  .job-title {
    margin-left: 1rem;
    font-size: 12px;
    color: #111827;
  }
`;




export const MenuItems = styled(Menu.Items)`
  position: absolute;
  right: 0;
  z-index: 20;
  margin-top: 0.5rem;
  width: 12rem;
  transform-origin: top right;
  border-radius: 0.375rem;
  background-color: #000000;
  padding: 0.5rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const MenuItem = styled(Menu.Item)`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #ffffff;
  text-decoration: none;
  text-align: left;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: #f9fafb;
    color: #000000;
  }

  &.active {
    background-color: #f3f4f6;
  }
`;

export const MenuItemLink = styled(Link)`
  display: block;
`;

export const MenuItemButton = styled.button`
  display: block;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  text-transform: none;
  border: 0 solid transparent;
  font-family: inherit;
  width: 100%;
`;