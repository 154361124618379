import React from "react";

import shell from "../../imgs/game/shell_logo.png";
import homelevel from "../../imgs/homelevel.jpg";
import Button from "../Button";
import {GameSectionContainer, ImageShell, Overlay} from "./style";

const GameSection = () => {
  return (
      <GameSectionContainer $image={homelevel}>
        <Overlay/>
        <ImageShell src={shell} alt="shell"/>
        <Button text="Join the Playtest!" click="/playtest" red large/>
      </GameSectionContainer>
  )
};


export default GameSection