import React, { useEffect, useRef } from "react";
import gsap, { TimelineMax, Power2 } from "gsap";

import img from "../imgs/o.png";

export default function LoadSpinner() {
  const o1 = useRef();
  const o2 = useRef();
  const o3 = useRef();

  const style = { width: 50, hwight: 50, filter: "brightness(0)" };

  useEffect(() => {
    var tl = new TimelineMax({ repeat: -1, repeatDelay: 0.5 });
    tl.to(o1.current, { y: 0 });
    tl.to(o3.current, { y: 0 });
    tl.to(o1.current, 0.3, { y: -50, ease: Power2.easeOut });
    tl.to(o1.current, 0.3, { y: 0, ease: Power2.easeIn });
    tl.to(o3.current, 0.3, { y: 50, ease: Power2.easeOut });
    tl.to(o3.current, 0.3, { y: 0, ease: Power2.easeIn });
    tl.to(o1.current, 0.2, { y: -35, ease: Power2.easeOut });
    tl.to(o1.current, 0.2, { y: 0, ease: Power2.easeIn });
    tl.to(o3.current, 0.2, { y: 35, ease: Power2.easeOut });
    tl.to(o3.current, 0.2, { y: 0, ease: Power2.easeIn });

    gsap.fromTo(
      o1.current,
      { rotate: "0deg" },
      {
        rotate: "359deg",
        ease: "sine.inOut",
        duration: 1,
        repeat: -1,
      }
    );
    gsap.fromTo(
      o2.current,
      { rotate: "80deg" },
      {
        rotate: "429deg",
        ease: "sine.inOut",
        duration: 0.7,
        delay: 0.2,
        repeat: -1,
      }
    );
    gsap.fromTo(
      o3.current,
      { rotate: "0deg" },
      {
        rotate: "-719deg",
        ease: "sine.inOut",
        duration: 0.7,
        delay: 0.5,
        repeat: -1,
      }
    );
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
      }}
    >
      <img alt="o1" src={img} ref={o1} style={style} />
      <img alt="o2" src={img} ref={o2} style={style} />
      <img alt="o3" src={img} ref={o3} style={style} />
    </div>
  );
}
