import React, {useEffect, useRef,} from "react";
import Button from "../components/Button";
import Section from "../components/Section";
import gsap from "gsap";
import frogmask from "../imgs/frogmask.png";
import ensemble from "../imgs/ensemble.png";
import withTracker from "../hoc/withTracker";
import Values from "../components/Culture/Values";
import {CustomLink, H1, H2, H3, JustifyCenter, Space} from "../styles/global-style";


function Culture() {
  const header = useRef(null);
  const subheader = useRef(null);

  useEffect(() => {
    document.body.style =
      "background: conic-gradient(from -85deg at 49.31% 52.18%, #e512b5 -60.02deg, #fa4f0f 169.59deg, #e512b5 299.98deg, #fa4f0f 529.59deg);";

    gsap.fromTo(
      header.current,
      {scale: 2, opacity: 0},
      {scale: 1, opacity: 1, duration: 3, ease: "power2.out"}
    );
    gsap.fromTo(
      subheader.current,
      {y: 40, opacity: 0},
      {y: 0, opacity: 1, duration: 0.5, delay: 2, ease: "power4.out"}
    );

  }, []);

  return (
    <Space mt={120}>
      <Section>
        <H1 ref={header} fontSize={100}>
          Our Culture
        </H1>
        <H2 ref={subheader} fontSize={40} mt={20}>
          ... Is Our Everything.
        </H2>
      </Section>

      <Values/>

      <Space mt={window.innerWidth < 900 ? 80 : 0}/>

      <Section img={frogmask} offset={70} height="140%" left>
        <H2 mb={0} mt={window.innerWidth < 900 ? 80 : 0}>As For</H2>
        <h1>Leadership</h1>
        <h3 className="font-neue-haas">
          People don't quit bad jobs, they quit bad managers. We have
          implemented meritocracy before and seen its success.
        </h3>
        <Button text="Our Take On Leadership" click="/leadership" black large/>
      </Section>

      <Section img={ensemble} offset={70} height="180%" right>
        <H2 mb="0">The Opportunity Of</H2>
        <h1>Diversity</h1>
        <H3 className="font-neue-haas">
          We want to make games for a large audience and we believe in diversity
          as a factor for success, resilience, and most importantly; a thriving
          work place. To cultivate this we need people with a wide array of
          backgrounds, perspectives and skills that share our values & passion
          for games and game making.
        </H3>
        <H3 className="font-neue-haas">
          Diverse perspectives are
          {" "}
          <CustomLink
            $underline
            color="#000"
            href="https://www.bcg.com/publications/2018/how-diverse-leadership-teams-boost-innovation.aspx"
            target="_blank" rel="noreferrer">scientifically proven</CustomLink>
          {" "}
          to boost creativity & innovation and we eagerly look forward to a
          future where we get to include ALL voices, backgrounds and
          perspectives.
        </H3>
      </Section>

      <Section text="Sound Like You?" full/>

      <Space mb="90px">
        <JustifyCenter>
          <Button text="Join our team" click="/jobs" black large/>
        </JustifyCenter>
      </Space>
    </Space>
  );
}

export default withTracker(Culture);
