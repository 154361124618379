import React from "react";
import Button from "../Button";

import logo from "../../imgs/Logo_White.png";
import discord from "../../imgs/Icon_Discord.png";
import discordOver from "../../imgs/Icon_Discord_Glitch.png";
import linkedin from "../../imgs/Icon_LinkedIn.png";
import linkedinOver from "../../imgs/Icon_LinkedIn_Glitch.png";
import facebook from "../../imgs/Icon_Facebook.png";
import facebookOver from "../../imgs/Icon_Facebook_Glitch.png";
import mail from "../../imgs/Icon_Mail.png";
import mailOver from "../../imgs/Icon_Mail_Glitch.png";
import twitter from "../../imgs/twitter.png";
import twitterOver from "../../imgs/twitter_Glitch.png";
import SocialMediaIcons from "./SocialMediaIcons";
import {Container, Flex, FooterEnd, FooterWrapper, LogoImage, FooterRightIcons} from "./style";

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <Flex>
          <LogoImage src={logo} alt="Modoyo"/>
          <Flex>
            <Button text="Press" click="/press" white/>
            <Button text="Terms & Conditions" click="/tos" white/>
            <Button text="Privacy policy" click="/privacy" white/>
          </Flex>
          <FooterRightIcons>
            <SocialMediaIcons
              src={discord}
              over={discordOver}
              size={24}
              url="https://discord.gg/projectshell"
            />
            <SocialMediaIcons
              src={mail}
              over={mailOver}
              size={24}
              url="mailto:hello@modoyo.com"
            />
            <SocialMediaIcons
              src={linkedin}
              over={linkedinOver}
              size={24}
              url="https://www.linkedin.com/company/modoyo"
            />
            <SocialMediaIcons
              src={facebook}
              over={facebookOver}
              size={24}
              url="https://www.facebook.com/modoyogames"
            />
            <SocialMediaIcons
                src={twitter}
                over={twitterOver}
                size={24}
                url="https://twitter.com/Modoyostudios"
            />
          </FooterRightIcons>
        </Flex>
        <FooterEnd>
          © {new Date().getFullYear()} Modoyo AB - All rights reserved.
        </FooterEnd>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
