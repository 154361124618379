import React, {useContext, useLayoutEffect} from "react";
import Section from "../components/Section";
import Ring from "../components/Ring";
import Button from "../components/Button";
import {Theme} from "../utils/Theme";

// import escapeHtml from "escape-html";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GameSlider from "../components/GameSlider";
import withTracker from "../hoc/withTracker";
import shell from "../imgs/game/shell_logo.png"

import {
  FlexCenterCol,
  H2,
  JustifyCenter,
  Space,
  TextCenter
} from "../styles/global-style";
import {Content, GameSignUpTest, Item} from "../components/Game/style";
import {ImageShell} from "../components/Home/style";

function Game() {
  const {setColor} = useContext(Theme);
  /*const [gameDetails, setGameDetail] = useState();*/


  useLayoutEffect(() => {
    setColor("lightBlue");

    document.body.style =
      "background: linear-gradient(131deg, #04A6E9 11.70%, #60E0FA 31.24%, #EDEDED 64.21%)";

   /* const getGameDetail = async () => {
      fetch(`/.netlify/functions/getgamedetails`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setGameDetail(data);
        })
        .catch(() => {
        })
    }

    getGameDetail()*/
  }, [setColor]);


  return (
    <>
      <Ring top={530} right={-286} rotate={180} opacity={.4} scale={0.7} white/>
      <Ring top={630} left={-600} scale={1.3} opacity={.6} rotate={100} white/>

      <div style={{height: window.innerWidth < 900 ? 150 : 0}}/>

      <Section top={300}>
        <FlexCenterCol>
          <ImageShell src={shell} alt="shell" big="75%"/>
          <div>
            {/*<H2 fontWeight={600}>{gameDetails?.header}</H2>*/}
            <H2 fontWeight={600}>Join us in shaping a new kind of FPS</H2>
            <Space margin="40px 0 50px 0">
              <GameSignUpTest>
                <Button
                  text="Sign up to Playtest"
                  callback={() => window.location.href = "https://playtest-signup.modoyo.com"}
                  red
                  large={window.innerWidth > 900}
                />
              </GameSignUpTest>
            </Space>
            {/*<H3 className="font-neue-haas" mt={40}>
              {gameDetails && parse(
                serialize({children: JSON.parse(gameDetails.first_paragraph)})
              )}
            </H3>*/}
            <Content className="font-neue-haas">
              <Item>
                Be the first to <b>shape our groundbreaking new game</b>, codenamed Project Shell. We want to make a <b>new
                way
                to play with your feedback!</b>
              </Item>
              <Item>
                Modoyo Devs put community first, and <b>you'll be working hand-in-hand</b> with them to shape the future
                of
                Project Shell!
              </Item>
              <Item>
                <b>Don't miss out</b> on this chance to play the next big shooter. <b>Sign up now and dive into a new
                world!</b>
              </Item>
              {/*{gameDetails && parse(
                serialize({children: JSON.parse(gameDetails.second_paragraph)})
              )}*/}
            </Content>
          </div>
          <Space margin="8rem 0 1rem 0">
            <JustifyCenter>
              <TextCenter>
                <H2 fontWeight={600} fontSize={40}>Here is a sneak peak at our in game world...</H2>
              </TextCenter>
            </JustifyCenter>
          </Space>
        </FlexCenterCol>
      </Section>
      <GameSlider/>
    </>
  );
}

/*const serialize = (item) => {
  if (item.text !== undefined) {
    let string = escapeHtml(item.text);
    if (item.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (item.italic) {
      string = `<em>${string}</em>`;
    }
    if (item.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = item.children.map((n) => serialize(n)).join("");

  switch (item.type) {
    case "headline-three":
      return `
        <div class="paragraph">
          <h3 class="heading-three">
            ${children}
          </h3>
        </div>
      `
    case "headline-four":
      return `
        <div class="paragraph">
          <h4 class="heading-four">
            ${children}
          </h4>
        </div>
      `
    case 'bulleted-list':
      return `<div class="paragraph"><ul>${children}</ul></div>`
    case 'list-item':
      return `<div class="paragraph"><li>${children}</li></div>`
    case 'numbered-list':
      return `<div class="paragraph"><ol>${children}</ol></div>`
    case "heading-one":
      return `<div class="paragraph"><h2 class="heading-one">${children}</h2></div>`;
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "ingress":
      if (children.trim() === "") {
        return `<div class="ingress"><p class="newstext empty-paragraph">${children}</p></div>`;
      } else {
        return `<div class="ingress"><p class="newstext">${children}</p></div>`;
      }
    case "paragraph":
      if (children.trim() === "") {
        return `<div class="paragraph"><p class="newstext empty-paragraph">${children}</p></div>`;
      } else {
        return `<div class="paragraph"><p class="newstext">${children}</p></div>`;
      }
    case "link":
      return `<a href="${escapeHtml(item.url)}">${children}</a>`;
    case "image":
      return `<center><img class="newsimg" alt="img" src="${escapeHtml(
        item.url
      )}"/></center>`;
    default:
      return children;
  }
};*/


export default withTracker(Game)