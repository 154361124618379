import React from "react";

export default function Section({
  right,
  full,
  wider,
  img,
  top,
  left,
  height,
  text,
  noncenter,
  button,
  offset,
  children,
  white,
}) {
  return (
    <div
      className={full ? "section-full" : "section"}
      style={{
        margin: wider ? "0px 80px" : "0px 110px",
        paddingTop: full ? 240 : top ? top : 280,
      }}
    >
      {full && (
        <>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: img ? 554 : text || button ? 150 : 0,
              width: "100vw",
              marginLeft: "50%",
              transform: "translateX(-50%)",
              overflow: "hidden",
              backgroundImage: "url(" + img + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            {img && (
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  background: "black",
                  opacity: "40%",
                }}
              />
            )}
            {text && (
              <h1
                style={{
                  color: img || white ? "white" : "black",
                  zIndex: 10,
                  marginTop: img && button ? -100 : 0,
                }}
              >
                {text}
              </h1>
            )}
            {button && (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {children}
              </div>
            )}
          </div>

          {!button && img && (
            <div
              style={{
                width: "100%",
                marginTop: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <center
                className="section-full-text"
                style={{
                  maxWidth: 800,
                }}
              >
                {children}
              </center>
            </div>
          )}

          {!button && !img && children && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <center
                className="section-full-text"
                style={{
                  maxWidth: 800,
                }}
              >
                {children}
              </center>
            </div>
          )}
        </>
      )}

      {!full && right && (
        <>
          <div
            className="section-graphics"
            style={{ maxWidth: wider ? "34%" : "default" }}
          >
            <img
              alt="img"
              className="section-graphics-img"
              src={img}
              style={{
                right: offset ? offset : 70,
                height: height ? height : "initial",
              }}
            />
          </div>
          <div
            className="section-text"
            style={{ maxWidth: wider ? "64%" : "default" }}
          >
            {children}
          </div>
        </>
      )}

      {!full && left && (
        <>
          <div
            className="section-text"
            style={{ maxWidth: wider ? "64%" : "default" }}
          >
            {children}
          </div>
          <div
            className="section-graphics"
            style={{ maxWidth: wider ? "34%" : "default" }}
          >
            <img
              alt="img"
              className="section-graphics-img"
              src={img}
              style={{
                left: offset ? offset : 0,
                height: height ? height : "initial",
              }}
            />
          </div>
        </>
      )}

      {!full && !left && !right && (
        <>
          <div
            style={{
              textAlign: noncenter ? "left" : "center",
              width: noncenter ? "100%" : "default",
              
            }}
          >
            {children}
          </div>
          {img && (
            <div className="section-graphics">
              <img
                alt="img"
                className="section-graphics-img"
                src={img}
                style={{ left: offset ? offset : 0 }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
