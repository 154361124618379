import React, {useState} from "react";
import {InlineStyledComponent, InvestorText, StyledInvestorImg} from "./style";

const Investor = (props) => {
  const [hovering, setHovering] = useState(false);
  return (
    <InlineStyledComponent>
      <StyledInvestorImg
        className="investor-img"
        src={props.src}
        alt="Investor"
        $hovering={hovering}
        $url={props.url}
        $mt={window.innerWidth < 900 ? '30px' : '0'}
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
        onClick={() => {
          if (props.url) window.open(props.url, "_blank");
        }}
      />

      {props.text && (
        <InvestorText $hovering={hovering}>{props.text}</InvestorText>
      )}
    </InlineStyledComponent>
  );
};


export default Investor