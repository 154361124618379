import React from "react"
import arrow from "../../imgs/arrow.png";
import {SlickArrowsContainer, SlickArrowsImage} from "./style";

function SlickPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <SlickArrowsContainer className={className} style={style}>
      <SlickArrowsImage src={arrow} onClick={onClick} $prev/>
    </SlickArrowsContainer>
  )
}

export default SlickPrevArrow