// Primary
const QuiGon = "#79e23a";
const Mace = "#9d11f4";

// Tints
const Yoda = "#ccf029";
const Cal = "#e512b5";
const Luke = "#146ef0";
const Plo = "#fa4f0f";

const Colors = {
  GREEN: QuiGon,
  PURPLE: Mace,
  ORANGE: Plo,
  BLUE: Luke,
  CAL: Cal,
  Gradients: {
    GREEN: "linear-gradient(to bottom right, " + Yoda + ", " + QuiGon + ")",
    GREEN_INV: "linear-gradient(to bottom right, " + QuiGon + ", " + Yoda + ")",
    PURPLE: "linear-gradient(to bottom right, " + Mace + ", " + Cal + ")",
    PURPLE_INV: "linear-gradient(to bottom right, " + Cal + ", " + Mace + ")",
    PURPLE_BLUE: "linear-gradient(to bottom right, " + Cal + ", " + Luke + ")",
    PURPLE_BLUE_INV:
      "linear-gradient(to bottom right, " + Luke + ", " + Cal + ")",
    ORANGE: "linear-gradient(to bottom right, " + Plo + ", " + Cal + ")",
    ORANGE_INV: "linear-gradient(to bottom right, " + Cal + ", " + Plo + ")",
  },
};

export default Colors;
