import React, { useState, useEffect, useCallback } from "react";

import ring from "../imgs/monogram_single_large.png";

export default function Ring({
  top,
  left,
  right,
  rotate,
  scale,
  white,
  opacity,
}) {
  const [parallax, setParallax] = useState(0);

  var style = {
    position: "absolute",
    width: 644,
    filter: white ? "brightness(1)" : "brightness(0)",
    opacity: opacity ? opacity : 1,
    transform: "rotate(" + (72 + parallax / 2) + "deg)",
    zIndex: -1,
  };
  if (left) style.left = left;
  else if (right) style.right = right;
  if (scale) style.transform += " scale(" + scale + ")";

  const handleScroll = useCallback(() => {
    setParallax(window.scrollY * 0.2);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <img
      alt="ring"
      className="ring"
      src={ring}
      style={{
        ...style,
        top: top + parallax,
      }}
    />
  );
}
