import React, {useState, useRef, useLayoutEffect} from "react";
import {useNavigate} from "react-router-dom";

import monogram from "../../../imgs/monogram_black.png";
import mask from "../../../imgs/ellipscrew.png";
import {
  Body,
  CardContainer,
  CardImage,
  ImagePlaceHolder,
  MonogramAbsolute,
  MonogramImage,
  ReadArticle,
  Title,
  TitleContainer
} from "./style";

export default function NewsCard(props) {
  const navigate = useNavigate();

  const {
    text,
    body,
    page,
    image,
    preview,
    whiteBg = false,
    id = null
  } = props

  const [bodyRows, setBodyRows] = useState(5);

  const titleRef = useRef(null);

  // Calculate the number of body rows based on the height of the title
  useLayoutEffect(() => {
    const height = titleRef.current.getBoundingClientRect().height

    if (height < 44) setBodyRows(6);
    else if (height < 88) setBodyRows(5);
    else if (height < 130) setBodyRows(3);
  }, [bodyRows, id]);

  return (
    <CardContainer
      $whiteBg={whiteBg}
      preview={preview}
      onClick={() => {
        if (preview) return;
        if (page) navigate(page);
      }}
    >
      <TitleContainer>
        <Title ref={titleRef}>{text}</Title>
        <Body className="font-neue-haas" $bodyRows={bodyRows}>{body}</Body>
      </TitleContainer>

      {image ? (
          // If an image is provided
          <>
            {!preview && <ReadArticle image={image}>Read article</ReadArticle>}
            <CardImage
              alt="Card Image"
              src={image}
              mask={mask}
            />
          </>
        )
        : (
          // If no image is provided
          <>
            <ImagePlaceHolder red/>
            <ReadArticle image={image} red>Learn More</ReadArticle>
            <MonogramAbsolute>
              <MonogramImage src={monogram} alt="monogramcard"/>
            </MonogramAbsolute>
          </>
        )}
    </CardContainer>
  )
}
