import React, {useEffect, useState} from 'react'

import {BenefitsHeading, IconImage, List, ListItem} from "./style";

import diamond from "../../imgs/diamond.png";
import helmet from "../../imgs/helmet.png";
import rainbow from "../../imgs/rainbow.png";
import shield from "../../imgs/shield.png";
import modoyo from "../../imgs/modoyo-monogram.png";
import sword from "../../imgs/sword.png";

const imageMap = {
  diamond: diamond,
  helmet: helmet,
  rainbow: rainbow,
  shield: shield,
  modoyo: modoyo,
  sword: sword,
};

function Benefits() {
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    getBenefits()
  }, [])

  const getBenefits = () => {
    fetch("/.netlify/functions/get-benefits", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setBenefits(data.filter((item) => item));
      });
  };

  return (
    <>
      <BenefitsHeading>Benefits</BenefitsHeading>
      <List>
        {benefits.map(benefit => (
          <ListItem key={benefit.id}>
            <IconImage src={imageMap[benefit.imageUrl]}/>
            {benefit.title}
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default Benefits