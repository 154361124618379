import styled from 'styled-components';

export const CardContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 300px;
  cursor: ${({preview}) => (preview ? 'cursor' : 'pointer')};
  z-index: 10;
  border-radius: 25px;
  background: ${({$whiteBg}) => ($whiteBg ? '#ffffff' : '#EDEDED')}; ;
  padding: 18px 33px;
  text-align: left;
  overflow: hidden;
  transition: 150ms ease;
  transform: scale(1);
  
  @media (max-width: 640px) {
    height: 450px;
  }
  
  &:hover {
    transform: scale(1.02);
  }
`;

export const CardImage = styled.img`
  position: absolute;
  height: 355px;
  top: 50%;
  left: 265px;
  transform: translateY(-50%);
  -webkit-mask-image: url(${props => props.mask});
  mask-image: url(${props => props.mask});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  overflow: hidden;

  @media (max-width: 640px) {
    position: absolute;
    height: 310px;
    
    top: -79px;
    left: 0;
    transform: translateY(0);
  }
`;



export const ReadArticle = styled.h3`
  position: absolute;
  bottom: 33px;
  left: ${props => (props.image ? '34px' : "unset")};
  right: ${props => (props.image ? "unset" : '18px')};
  padding: 0;
  margin: 0;
  font-size: 17px;
  text-decoration: underline;
  color: ${props =>
  props.image ? "black" : props.red ? "white" : "black"};


  @media (max-width: 640px) {
    position: relative;
    left: 0;
    bottom: 0;
    transform: translateY(0);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  justify-content: unset;

  @media (max-width: 640px) {
    justify-content: center;
    width: auto;
    height: auto;
  }
`;

export const Title = styled.div`
  margin-bottom: 10px;
  margin-top: 25px;
  z-index: 10;
  text-align: left;
  line-height: 1.4;
  font-size: 27px;
  font-weight: 700;
  
  @media (max-width: 640px) {
    margin-bottom: 10px;
    margin-top: 200px;
    font-size: 18px;

    display: block!important;
    display: -webkit-box!important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

export const Body = styled.div`
  display: -webkit-box;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({$bodyRows}) => $bodyRows};
  -webkit-box-orient: vertical;
  line-clamp: ${({$bodyRows}) => $bodyRows};
  margin: 15px 0;
  height: ${({$bodyRows}) => $bodyRows * 22}px;
  z-index: 10;
  font-weight: 500;
  
`

export const MonogramAbsolute = styled.div`
  position: absolute;
  right: -60px;
  top: -110px;

  @media (max-width: 640px) {
    top: -191px;
  }
`;

export const MonogramImage = styled.img`
  filter: invert(1);
  transform: scale(1.3);
`;

export const ImagePlaceHolder = styled.div`
  position: absolute;
  width: 200px;
  height: 140px;
  bottom: 0;
  right: 0;
  background: ${props => props.red ? "#E512B5" : "#C1EF2C"};

  @media (max-width: 640px) {
    bottom: -40px;
  }
`;