import React from "react";
import {H2} from "../../styles/global-style";
import {Section, SectionGraphics, SectionText} from "./style";
import shelly from "../../imgs/kojette.png";

const HeroSection = () => {
  return (
    <Section>
      <SectionText>
        <H2 mt="100px">Here To Show What</H2>
        <h1>Inclusivity, Community and A Love of Games</h1>
        <h2>Can Create</h2>
      </SectionText>
      <SectionGraphics $imageRight>
        <img src={shelly} alt="img"/>
      </SectionGraphics>
    </Section>
  )
};


export default HeroSection