import styled, {css} from 'styled-components';

export const FooterWrapper = styled.footer`
  background: black;
`
export const Container = styled.div`
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-block: 4.5rem;
  padding-inline: 2rem;
  ${props => props.$innerWidth && css`
    padding-top: ${props => props.$innerWidth < 900 ? '0' : '350px'};
  `}
  text-align : left;
  
  ${props => props.$sm && css`
    @media (min-width: 640px) {
      max-width: 640px;
    }
  `}

  ${props => props.$md && css`
    @media (min-width: 768px) {
      max-width: 768px;
    }
  `}

  ${props => props.$lg && css`
    @media (min-width: 1024px) {
      max-width: 1024px;
    }
  `}

  ${props => props.$xl && css`
    @media (min-width: 1280px) {
      max-width: 1280px;
    }
  `}

  ${props => props['2xl'] && css`
    @media (min-width: 1536px) {
      max-width: 1536px;
    }
  `}
`;

export const LogoImage = styled.img`
  width: 140px;
  align-self: center;
  @media (max-width: 640px) {
    margin-bottom: 20px;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 820px) {
    display: flex;
    justify-content: center;
  }
`

export const FooterEnd = styled.div`
  margin-top: 30px;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
`

export const FooterRightIcons = styled.div`
  @media (max-width: 640px) {
    margin-block: 30px;
  }
  `