import React, {useContext, useEffect, useLayoutEffect, useState} from 'react'
import {Container, FullHeight, SpaceY, Title} from "../../../styles/global-style";
import {Input, TextArea} from "../../../components/Admin/User/Account/style";
import {ImageCropperThumb} from "../../../components/ImageCropperThumb";
import {Theme} from "../../../utils/Theme";
import {Link, useNavigate, useParams} from "react-router-dom";
import Button from "../../../components/Button";

function TeamEdit() {
  const {setColor} = useContext(Theme);
  const navigate = useNavigate();
  const {idx} = useParams();

  const [team, setTeam] = useState({});
  const [profileUrl, setProfileUrl] = useState('')
  const [profileUrlHover, setProfileUrlHover] = useState('')
  const [inputValues, setInputValues] = useState({
    fullName: '',
    jobTitle: '',
    linkedinUrl: '',
    bio: '',
  });

  useLayoutEffect(() => {
    setColor("red");
    document.body.style =
      "background: conic-gradient(from -85deg at 50% 50%, #ccf029 0deg, #79e23a 64.86deg, #a4e931 176.24deg, #ccf029 360deg);";

    const getData = async () => { fetch("/.netlify/functions/getteam", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        const _id = parseInt(idx, 10);
        const item = data.filter((item) => item.id === _id)
        setTeam(...item);
      }); // Code logic here
    };

    getData()
  }, [setColor, idx]);


  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value
    }));
  };


  const addProfileImage = (header, content, published, thumbUrl) => {
    setProfileUrl(thumbUrl)
  }

  const addProfileImageHover = (header, content, published, thumbUrl) => {
    setProfileUrlHover(thumbUrl)
  }

  const editTeam = () => {
    fetch("/.netlify/functions/editteam", {
      method: "POST",
      body: JSON.stringify({
        id: idx,
        full_name: inputValues.fullName,
        job_title: inputValues.jobTitle,
        bio: inputValues.bio,
        thumb: profileUrl,
        thumb_hover: profileUrlHover,
        linkedin_url: inputValues.linkedinUrl
      }),
    }).then(() => {
      navigate("/narnia/team");
    })
  }


  useEffect(() => {
    if (team) {
      setProfileUrl(team.thumb)
      setProfileUrlHover(team.thumb_hover)

      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        fullName: team.full_name,
        jobTitle: team.job_title,
        bio: team.bio,
        linkedinUrl: team.linkedin_url,
      }));
    }
  }, [team])

  return (
    <FullHeight>
      <Container>
        <SpaceY number="1.5rem">
          <Link to="/narnia/team" style={{
            color: "black",
            textDecoration: "none",
            alignItems: "center",
            display: "flex",
            marginBottom: "2rem",
            marginTop: "1rem"
          }}><i
            className="fas fa-arrow-left" style={{marginRight: ".5rem", fontSize: "18px"}}/>Back</Link>
          <Title>Edit team</Title>
          <div>
            <label>Profile Picture</label>
            <ImageCropperThumb
              editPost={addProfileImage}
              thumb={profileUrl ? profileUrl : team?.thumb}
              hideCardPreview
            />
          </div>
          <div>
            <label>Profile Picture on Hover</label>
            <ImageCropperThumb
              editPost={addProfileImageHover}
              thumb={profileUrlHover ? profileUrlHover : team?.thumb_hover}
              hideCardPreview
            />
          </div>
          <div
            style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <label>Fullname</label>
            <Input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={inputValues.fullName ? inputValues.fullName : team?.full_name}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <label>Job Title</label>
            <Input
              type="text"
              name="jobTitle"
              placeholder="Head of Brand & Talent"
              value={inputValues.jobTitle ? inputValues.jobTitle : team?.job_title}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <label>Linkedin Url</label>
            <Input
              type="text"
              name="linkedinUrl"
              placeholder="https://www.linkedin.com/in/prolfile"
              value={inputValues.linkedinUrl ? inputValues.linkedinUrl : team?.linkedin_url}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <label>Biography</label>
            <TextArea
              name="bio"
              placeholder="Add Biography"
              rows="8"
              value={inputValues.bio ? inputValues.bio : team?.bio}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <Button text="Save Changes" black callback={editTeam}/>
          </div>
        </SpaceY>
      </Container>
    </FullHeight>
  )
}


export default TeamEdit