import React, {useRef, useState} from 'react';
import Cropper from 'react-cropper';

import {Dialog} from '@headlessui/react'
import {Transforms} from "slate";

import "cropperjs/dist/cropper.css";

import './style.css'
import Button from "./Button";

const ThisButton = (props) => {
  return (
    <div
      onMouseDown={(event) => {
        event.preventDefault();
        if (props.onMouseDown) props.onMouseDown();
      }}
      style={{
        height: 20,
        width: 20,
        background: props.active ? "black" : "transparent",
        padding: "4px 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        border: "1px solid black",
        cursor: "pointer",
        color: props.active ? "white" : "black",
        marginRight: 4,
      }}
    >
      <div className={props.image}/>
    </div>
  );
};

export const ImageCropper = (props) => {
  const {editor} = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedFilePath, setCroppedFilePath] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const cropper = useRef(null);
  const fileInputRef = useRef(null);

  const handleClose = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setOpenDialog(false);
  }

  const insertImage = (editor, url) => {
    const text = {text: ""};
    const image = {type: "image", url, children: [text]};
    const extraline = {type: "paragraph", url, children: [text]};
    Transforms.insertNodes(editor, image);
    Transforms.insertNodes(editor, extraline);
  }

  async function fileUploaded(xhr, editor) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // File uploaded successfully
      let response = JSON.parse(xhr.responseText);
      insertImage(editor, response.url);
    }
  }

  async function onFileSubmit(files, editor) {
    const url = `https://api.cloudinary.com/v1_1/dojgj0dvl/upload`;
    files.then((file) => {
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.onreadystatechange = fileUploaded.bind(this, xhr, editor);
      fd.append("upload_preset", "ufpksy5b");
      fd.append("tags", "card"); // Optional - add tag for image admin in Cloudinary
      fd.append("multiple", true);
      fd.append("file", file);
      xhr.send(fd);
    })
  }

  function base64StringtoFile(url, filename, mimeType) {
    if (url.startsWith('data:')) {
      var arr = url.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file = new File([u8arr], filename, {type: mime || mimeType});
      return Promise.resolve(file);
    }
    return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], filename, {type: mimeType}));
  }

  const confirmCroppedImage = async () => {
    onFileSubmit(croppedFile, editor).then(() => {
      handleClose();
    })
  };

  const fileSelectHandler = async (event) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      setSelectedFile(newFile);
      setOpenDialog(true);
    }
  }

  const _crop = () => {
    const str = cropper.current.cropper.getCroppedCanvas({
      width: 1280,
      height: 720,
    }).toDataURL(selectedFile.type, 1);

    setCroppedFilePath(str);
    setCroppedFile(base64StringtoFile(str, selectedFile.name, 'image/jpeg'));
  }

  return (
    <>
      <div>
        <ThisButton
          onMouseDown={() => {
            fileInputRef.current.click();
          }}
          image="fas fa-image"
        />
        <input
          ref={fileInputRef}
          type="file"
          accept="image/png, image/jpeg, image/gif"
          style={{position: "absolute", display: "none"}}
          onChange={fileSelectHandler}
          multiple={true}
        />
      </div>
      <Dialog className="dialog" open={openDialog} onClose={handleClose}>
        <Dialog.Panel>
          <Dialog.Title className="title">Upload Image</Dialog.Title>
          <div style={{display: "flex"}}>
            {selectedFile && (
              <Cropper
                ref={cropper}
                style={{height: 400, width: 700, marginBottom: 30}}
                // aspectRatio={1}
                src={selectedFile?.preview}
                preview=".img-preview"
                guides={false}
                viewMode={1}
                crop={_crop}
                checkOrientation={false}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                responsive={true}
              />
            )}
            {croppedFilePath && (
              <span style={{display: "flex", flexDirection: "column"}}>
              <span style={{fontWeight: "bolder", marginLeft:40, marginBottom: 20}}>Cropped Image: </span>
                <div className="preview-image-cropper">
                  <img alt="Cropped" src={croppedFilePath} style={{width: "100%", borderRadius: 12}}/>
                </div>
            </span>
            )}
          </div>
          <Button text="Confirm Image" callback={confirmCroppedImage} red/>
          <Button text="Cancel" callback={handleClose}/>
        </Dialog.Panel>
      </Dialog>
    </>
  );
};