import React, {forwardRef, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Theme} from "../../utils/Theme";
import ring from "../../imgs/monogram_single_large.png";

let timer = null;

const Values = () => {
    const [current, setCurrent] = useState(1);
    const [descHeight, setDescHeight] = useState(400);
    const [clicked, setClicked] = useState(false);
    const {setColor} = useContext(Theme);

    const desc1 = useRef(null);
    const desc2 = useRef(null);
    const desc3 = useRef(null);
    const descHolder = useRef(null);
    const currentRef = useRef(null);

    const setTimer = useCallback(() => {
        function animate() {
            if (currentRef.current < 2) setCurrent(currentRef.current + 1);
            else setCurrent(0);

            setTimer();
        }

        if (timer) clearTimeout(timer);
        timer = setTimeout(animate, clicked ? 18000 : 12000);
    }, [clicked]);

    useEffect(() => {
        currentRef.current = current;
        setTimer();

        return () => {
            clearTimeout(timer);
        };
    }, [current, setTimer]);

    useLayoutEffect(() => {
        setColor("green");

        setDescHeight(
            Math.max(
                desc1.current.getBoundingClientRect().height,
                desc3.current.getBoundingClientRect().height,
                desc3.current.getBoundingClientRect().height
            ) + 50
        );
    }, [setTimer, setColor]);

    function clickedRing(id) {
        if (window.innerWidth >= 900) {
            descHolder.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "nearest",
            });
        }
        setCurrent(id);
        setClicked(true);
    }

    return (
        <>
            <div
                className="cultureValues"
                style={{
                    width: "100%",
                    height: 500,
                    margin: "230px 0px 50px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Ring
                    text="Humble"
                    id={0}
                    current={current}
                    setCurrent={clickedRing}/>
                <Ring
                    text="Positive"
                    id={1}
                    current={current}
                    setCurrent={clickedRing}
                />
                <Ring
                    text="Industry Drivers"
                    id={2}
                    current={current}
                    setCurrent={clickedRing}
                />
            </div>
            <div
                ref={descHolder}
                style={{
                    position: "relative",
                    display: "flex",
                    width: "100%",
                    overflow: "hidden",
                    height: descHeight,
                }}
            >
                <Desc
                    id={0}
                    ref={desc1}
                    current={current}
                    title="VULNERABLE - CARING - SELFLESS"
                    body="Humble team members are quick to point out the contributions of others and slow to seek attention for their own. We share credit, emphasize team over self and define success collectively rather than individually. This along with capacity for vulnerability, to say 'I´m sorry' or 'I was wrong', which is the birth place of trust."
                    setCurrent={clickedRing}
                />
                <Desc
                    id={1}
                    ref={desc2}
                    current={current}
                    title="OPTIMISTIC - ADDITIVE - CONSTRUCTIVE"
                    body="Capacity to choose positivity adds to an uplifting culture and make colleagues want to engage into a common mission, by providing a team environment that is psychologically safe. Positive team members strive to add value to a discussion by looking hard for a constructive way forward rather than only stating the problems."
                    setCurrent={clickedRing}
                />
                <Desc
                    id={2}
                    ref={desc3}
                    current={current}
                    title="PROGRESSIVE - CREATIVE - CURIOUS"
                    body="Industry drivers are people who feel relentless curiosity, and experience happiness when progressing towards results that validate their hypotheses. They don’t seek approval for making smart, goal oriented decisions but rather inspire others to go boldly into the unknown."
                    setCurrent={clickedRing}
                />
            </div>

            <Spinner current={current} setCurrent={clickedRing}/>
        </>
    );
};


const Ring = ({id, text, current, setCurrent}) => {
    const [hovering, setHovering] = useState(false);
    const [touchMove, setTouchMove] = useState(null);

    const isMobile = window.innerWidth < 900;

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px 15px",
                transition: "150ms ease",
                width: current === id ? "38%" : "20%",
                opacity: current !== id && isMobile ? 0.2 : 1,
                zIndex: current === id ? 10 : 1,
            }}
            onTouchStart={(e) => {
                setTouchMove(e.changedTouches[0].clientX);
            }}
            onTouchEnd={(e) => {
                const dir = touchMove - e.changedTouches[0].clientX;
                if (Math.abs(dir) > 140)
                    if (dir < 0) {
                        setCurrent(current === 0 ? 2 : current - 1);
                    } else setCurrent(current === 2 ? 0 : current + 1);
            }}
        >
            <img
                alt="ring"
                src={ring}
                style={{
                    width: isMobile ? "240%" : "100%",
                    filter: "brightness(0)",
                    transform:
                        current === id || hovering ? "rotate(95deg)" : "rotate(-25deg)",
                    transition: "150ms ease",
                }}
            />
            <div
                style={{
                    fontFamily: "Termina",
                    fontWeight: 600,
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "black",
                    fontSize: current === id ? "3.5vh" : "2.1vh",
                    maxWidth: current === id ? 200 : 100,
                    textAlign: "center",
                    marginTop: 10,
                    cursor: "pointer",
                }}
            >
                {text}
            </div>
            {current !== id && (
                <div
                    style={{
                        position: "absolute",
                        width: "80%",
                        minWidth: 100,
                        height: "90%",
                        borderRadius: "50%",
                        cursor: "pointer",
                        zIndex: 200,
                    }}
                    onClick={() => {
                        setCurrent(id);
                    }}
                    onMouseEnter={() => {
                        setHovering(true);
                    }}
                    onMouseLeave={() => {
                        setHovering(false);
                    }}
                />
            )}
        </div>
    );
};

const Desc = forwardRef(({id, current, title, body, setCurrent}, ref) => {
    const [touchMove, setTouchMove] = useState(null);
    const lefts = [
        ["50%", "50%", "50%"],
        ["-50%", "50%", "100%"],
        ["-100%", "-50%", "50%"],
    ];
    return (
        <>
            <div
                ref={ref}
                style={{
                    position: "absolute",
                    width: 800,
                    maxWidth: "90%",
                    left: lefts[current][id],
                    opacity: current === id ? 1 : 0,
                    transform: "translateX(-50%)",
                    transition: "300ms ease",
                    textAlign: "center",
                }}
                onTouchStart={(e) => {
                    setTouchMove(e.changedTouches[0].clientX);
                }}
                onTouchEnd={(e) => {
                    const dir = touchMove - e.changedTouches[0].clientX;
                    if (Math.abs(dir) > 140)
                        if (dir < 0) {
                            setCurrent(current === 0 ? 2 : current - 1);
                        } else setCurrent(current === 2 ? 0 : current + 1);
                }}
            >
                <h2
                    className="valuesHeader"
                    style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        justifyContent: "center",
                        fontSize: 32,
                        marginTop: 50,
                    }}
                >
                    {title}
                </h2>
                <h3 className="font-neue-haas valuesText" style={{color: "black"}}>
                    {body}
                </h3>
            </div>
        </>
    );
}, []);

const Spinner = ({current, setCurrent}) => {
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 40,
            }}
        >
            <div
                style={{
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 200,
                }}
            >
                <TinyRing id={0} current={current} setCurrent={setCurrent}/>
                <TinyRing id={1} current={current} setCurrent={setCurrent}/>
                <TinyRing id={2} current={current} setCurrent={setCurrent}/>
                <div
                    style={{
                        position: "absolute",
                        top: 50,
                        left: 60 + current * 40,
                        transform: "translateX(-50%)",
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        background: "black",
                        transition: "300ms ease",
                    }}
                />
            </div>
        </div>
    );
};

const TinyRing = ({id, current, setCurrent}) => {
    const [hovering, setHovering] = useState(false);

    return (
        <img
            alt="ring"
            src={ring}
            style={{
                width: id === current ? 40 : 20,
                height: id === current ? 40 : 20,
                filter: "brightness(0)",
                transform:
                    current === id || hovering ? "rotate(95deg)" : "rotate(-25deg)",
                transition: "150ms ease",
                padding: "0px 10px",
                cursor: current !== id ? "pointer" : "default",
            }}
            onClick={() => {
                setCurrent(id);
            }}
            onMouseEnter={() => {
                setHovering(true);
            }}
            onMouseLeave={() => {
                setHovering(false);
            }}
        />
    );
};

export default Values;