import React, { useState} from 'react'

function SocialMediaIcons(props) {
  const [hover, setHover] = useState(false);
  return (
    <img
      src={hover ? props.over : props.src}
      alt="footeritem"
      style={{
        width: props.size ? props.size : "default",
        verticalAlign: "middle",
        cursor: props.url ? "pointer" : "initial",
        margin: "0px 10px",
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onClick={() => {
        if (props.url) window.open(props.url, "_blank");
      }}
    />
  );
}


export default SocialMediaIcons