import React, {useState, useEffect, useCallback, useContext} from "react";
import Button from "./Button";
import Colors from "../utils/colors";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext} from "../utils/AuthContext";

import logo from "../imgs/Logo_Black.png";
import hamburger from "../imgs/Icon_Menu.png";
import {CloseMobile} from "./Admin/News/style";

const defaultTop = window.innerWidth > 900 ? 100 : 40;
const maxWidth = window.innerWidth > 900 ? 260 : 220;
const minWidth = 170;

export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const {user} = useContext(AuthContext);

  const [mod, setMod] = useState({
    width: maxWidth,
    opacity: -100,
    top: defaultTop,
    factor: 0,
  });
  const [open, setOpen] = useState(false);

  const handleScroll = useCallback(() => {
    const scroll = Math.max(window.scrollY, 1);
    const factor = Math.min(scroll, 100) / 100;

    setMod({
      top: defaultTop - factor * defaultTop,
      factor: factor,
      width: maxWidth - factor * (maxWidth - minWidth),
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    console.log(pathname)
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, pathname]);


  const isCategoryPage = (pathname, category) => {
    const regex = new RegExp(`^/${category}/\\d+$`);
    return regex.test(pathname);
  }


  return (
    <>
      <div className="container">
        <div
          className="mobileMenu"
          style={{
            position: "fixed",
            left: 0,
            top: mod && mod.top ? mod.top : 0,
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, " + mod.factor * 0.7 + ")",
            backdropFilter: "blur(10px)",
            zIndex: 100,
          }}
        >
          <div
            style={{
              margin: "10px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              alt="logo"
              src={logo}
              style={{
                width: mod.width,
                cursor: "pointer",
                zIndex: 2,
                filter: "invert(" + mod.factor + ")",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
            {!user && (
              <div
                className="hamburger"
                onClick={() => {
                  setOpen((prev) => !prev);
                }}
                style={{display: "flex", alignItems: "center"}}
              >
                <img
                  alt="hamburger"
                  src={hamburger}
                  style={{
                    filter: "invert(" + (1 - mod.factor) + ")",
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className="desktopMenu"
          style={{
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            width: 1226,
            maxWidth: "90%",
            top: mod && mod.top ? mod.top : 0,
            padding: 10,
            zIndex: 100,
          }}
        >
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: mod && mod.factor ? (mod.factor - 1) * 200 : 0,
              transform: "translateX(-50%)",
              width: "100vw",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, " + mod.factor * 0.7 + ")",
              backdropFilter: "blur(" + mod.factor * 20 + "px)",
              zIndex: -1,
            }}
          />
          <img
            alt="logo"
            src={logo}
            style={{
              width: mod.width,
              cursor: "pointer",
              zIndex: 2,
              marginTop: mod && mod.factor ? 7 * mod.factor : 0,
              filter: "invert(" + mod.factor + ")",
            }}
            onClick={() => {
              navigate("/");
            }}
          />
          {!user && (
            <>
              <div className="menu" style={{marginRight: 60}}>
                <Button
                  text="The Team"
                  click="/team"
                  menuitem
                  invert={mod.factor}
                />
                <Button
                  text="The Way"
                  click="/culture"
                  menuitem
                  invert={mod.factor}
                />
                <Button text="News" click="/news" menuitem invert={mod.factor}/>

                {isCategoryPage(pathname, "news") || isCategoryPage(pathname, "job") ? (
                  <Button text="Open roles" click="/jobs" menuitem green/>
                ) : (
                  <Button text="Open roles" click="/jobs" menuitem whiteBg/>
                )}

                {mod && mod.top === 0 && <Button text="Join the Playtest!" click="/playtest" red/>}

              </div>
              <div
                className="hamburger"
                onClick={() => {
                  setOpen((prev) => !prev);
                }}
              >
                <img alt="hamburger" src={hamburger}/>
              </div>
            </>
          )}
        </div>

        {!user && open && (
          <div
            className="menu-mobile"
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              verticalAlign: "middle",
              transition: "200ms ease",
              overflow: "hidden",
              background: "#00000088",
              backdropFilter: "blur(6px)",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                display: "inline-flex",
                flexDirection: "column",
                justifyContent: "center",
                background: Colors.Gradients.GREEN_INV,
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 18,
                }}
                onClick={() => {
                  navigate("/");
                  setOpen(false);
                }}
              >
                <i
                  className="fas fa-home"
                  style={{fontSize: 31, color: "black"}}
                />
              </div>
              <CloseMobile
                className="fas fa-times"
                $delete
                onClick={(e) => {
                  setOpen(false);
                  e.stopPropagation();
                }}
              />
              <Button
                text="The Team"
                click="/team"
                callback={() => {
                  setOpen(false);
                }}
                menuitem
                large
              />
              <Button
                text="The Way"
                click="/culture"
                callback={() => {
                  setOpen(false);
                }}
                menuitem
                large
              />
              <Button
                text="Leadership Values"
                click="/leadership"
                callback={() => {
                  setOpen(false);
                }}
                menuitem
                large
              />
              <Button
                text="News"
                click="/news"
                callback={() => {
                  setOpen(false);
                }}
                menuitem
                large
              />
              <Button
                text="Open roles"
                click="/jobs"
                whiteBg
                large
                callback={() => {
                  setOpen(false);
                }}
              />
              <Button
                text="Join the Playtest!"
                click="/playtest"
                red
                large
                callback={() => {
                  setOpen(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
