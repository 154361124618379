import {useContext, useState, useLayoutEffect} from 'react';
import {Theme} from "../utils/Theme";

const useTeamResults = () => {
  const {setColor} = useContext(Theme)

  const [data, setData] = useState()

  useLayoutEffect(() => {
    // Set the color
    setColor('green')

    // Set the background gradient
    document.body.style =
      "background: conic-gradient(from 163.66deg at 50% 50%, #146EF0 -76.87deg, #B512E0 46.87deg, #9025E6 181.88deg, #146EF0 283.13deg, #B512E0 406.87deg);";

    // Fetch data
    getData()

  }, [setColor])

  const getData = async () => {
    fetch(`/.netlify/functions/getjobdetails`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch(() => {
      })
  }

  return [data]
}

export default useTeamResults;
