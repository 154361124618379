import React, {useState, useLayoutEffect, useContext, useRef, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Ring from "../components/Ring";
import LoadSpinner from "../components/LoadSpinner";
import {Theme} from "../utils/Theme";
import {NewsListContainer, PopoverContainer, PopoverContent, PopoverPanel} from "../components/News/style";
import NewsCard from "../components/News/Card";
import {Node} from "slate";
import {ProfileImage, ProfilePlaceholder, ArticleHeading} from "../components/News/style";
import {Author, FlexCenter, FullName, Headline2, JobTitle, Published, SpaceX, SpaceY} from "../styles/global-style";
// import serialize from "../components/SerializeEditor";
import parse from "html-react-parser";
import escapeHtml from "escape-html";
import {Popover} from '@headlessui/react'
import linkedinBlack from "../imgs/Icon_LinkedIn_black.png";
import linkedinBlackOver from "../imgs/Icon_LinkedIn_black_Glitch.png";
import SocialMediaIcons from "../components/Footer/SocialMediaIcons";
import {Container} from "../components/Footer/style";
import withTracker from "../hoc/withTracker";
import {Helmet} from "react-helmet-async";

const timeoutDuration = 120

function NewsArticle() {
  const {setColor} = useContext(Theme);
  const {id} = useParams();
  const navigate = useNavigate();
  const triggerRef = useRef()
  const timeOutRef = useRef()

  const [newsDetail, setNewsDetail] = useState();
  const [news, setNews] = useState();

  useLayoutEffect(() => {
    setColor("green");
    document.body.style = "background: #EDEDED;";
    const getNewsArticle = async () => {
      fetch(`/.netlify/functions/getposts`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          const _id = parseInt(id, 10);
          setNews(data.filter((item) => item.published && item.id !== _id));
        });
    }

    const getNewsDetail = async () => {
      fetch(`/.netlify/functions/getpost?id=${id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.error) navigate('/news');
          setNewsDetail(data);
        })
        .catch(() => {
          navigate("/news")
        })
    }

    getNewsDetail();
    getNewsArticle()
  }, [setColor, navigate, id]);


  const calculateReadingTime = (content) => {
    const wordCount = content.trim().split(/\s+/).length;
    const averageReadingSpeed = 200;
    const readingTimeMinutes = Math.ceil(wordCount / averageReadingSpeed);

    if (readingTimeMinutes < 1) {
      const readingTimeSeconds = Math.ceil((wordCount / averageReadingSpeed) * 60);
      return `${readingTimeSeconds} sec read`;
    } else if (readingTimeMinutes < 60) {
      return `${readingTimeMinutes} min read`;
    } else {
      const readingTimeHours = Math.floor(readingTimeMinutes / 60);
      return `${readingTimeHours} hour read`;
    }
  }

  const handleEnter = (isOpen) => {
    if (window.innerWidth > 900) {
      clearTimeout(timeOutRef.current)
      !isOpen && triggerRef.current?.click()
    }

  }

  const handleLeave = (isOpen) => {
    if (window.innerWidth > 900) {
      timeOutRef.current = setTimeout(() => {
        isOpen && triggerRef.current?.click()
      }, timeoutDuration)
    }
  }


  const height = useMemo(() => window.innerWidth < 900 ? 150 : 0, []);

  return (
    <>
      <Ring
        top={280}
        right={-286}
        scale={1.3}
        rotate={40}
        opacity={0.2}
        white
      />
      <Ring
        top={1480}
        left={-200}
        scale={1.3}
        rotate={140}
        opacity={0.2}
        white
      />
      <Ring top={2380} right={-200} scale={1} rotate={10} opacity={0.2} white/>
      <Ring
        top={3000}
        left={-286}
        scale={2}
        rotate={120}
        opacity={0.3}
        white
      />

      <div style={{height}}/>

      {newsDetail ? (
        <Container $md $innerWidth={window.innerWidth}>
          <SpaceY number="4rem">
            <SpaceY number="2rem">
              {newsDetail && newsDetail.header && (
                <Helmet>
                  <title>{newsDetail.header}</title>
                </Helmet>
              )}
              <ArticleHeading>{newsDetail && newsDetail.header}</ArticleHeading>
              {newsDetail && newsDetail.author && (
                <FlexCenter>
                  <PopoverContainer>
                    {({open}) => (
                      <div
                        onMouseEnter={() => handleEnter(open)}
                        onMouseLeave={() => handleLeave(open)}
                      >
                        <Popover.Button as="div" ref={triggerRef}>
                          {newsDetail.author.profile_url ?
                            <ProfileImage src={newsDetail.author.profile_url}/>
                            :
                            <ProfilePlaceholder>
                              A
                              {newsDetail.author.full_name.substring(0, 1)}
                            </ProfilePlaceholder>
                          }
                        </Popover.Button>

                        <PopoverPanel width="30rem">
                          <PopoverContent>
                            <FlexCenter>
                              {newsDetail.author.profile_url ?
                                <div><ProfileImage src={newsDetail.author.profile_url}/></div>
                                :
                                <div><ProfilePlaceholder>
                                  {newsDetail.author.full_name.substring(0, 1)}
                                </ProfilePlaceholder></div>
                              }
                              <Author>
                                <FullName className="popover">
                                  {newsDetail.author.full_name}
                                </FullName>
                                <JobTitle className="popover">
                                  <SpaceX number=".5rem">
                                    <span>{newsDetail.author.job_title}</span>
                                    <SocialMediaIcons
                                      src={linkedinBlack}
                                      over={linkedinBlackOver}
                                      size={24}
                                      url={newsDetail.author.linkedin_url}
                                    />
                                  </SpaceX>
                                </JobTitle>
                              </Author>
                              {newsDetail && (
                                <Published>
                                  {/*Posted on {dateFormat(newsDetail.created_at)} ·*/}
                                </Published>
                              )
                              }
                            </FlexCenter>
                          </PopoverContent>
                        </PopoverPanel>
                      </div>
                    )
                    }
                  </PopoverContainer>

                  <Author>
                    <FullName>
                      {newsDetail.author.full_name}
                    </FullName>
                    <JobTitle>
                      <SpaceX number=".5rem">
                        <span>{newsDetail.author.job_title}</span>
                        <span>·</span>
                        <span>{newsDetail && calculateReadingTime(serializeNews(JSON.parse(newsDetail.newbody)))}</span>
                      </SpaceX>
                    </JobTitle>
                  </Author>
                </FlexCenter>
              )}
            </SpaceY>
            <div className="font-neue-haas text-align-justify news-text">
              {newsDetail && parse(
                serialize({children: JSON.parse(newsDetail.body)})
              )}
            </div>
          </SpaceY>
        </Container>
      ) : (
        <LoadSpinner/>
      )}

      <Headline2 textalign="center">Other articles you might like...</Headline2>
      {news && news.length > 0 ? (
        <NewsListContainer>
          {news.map((item) => {
            if (!item.newbody) return <></>;

            const content = JSON.parse(item.newbody);

            return (
              <NewsCard
                key={item.id}
                text={item.header}
                body={serializeNews(content)}
                page={"/news/" + item.id}
                image={item.thumb}
                red
                whiteBg
                id={item.id}
              />
            );
          })}
        </NewsListContainer>
      ) : (
        <LoadSpinner/>
      )}
    </>
  )
}

const serialize = (item) => {
  if (item.text !== undefined) {
    let string = escapeHtml(item.text);
    if (item.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (item.italic) {
      string = `<em>${string}</em>`;
    }
    if (item.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = item.children.map((n) => serialize(n)).join("");

  switch (item.type) {
    case "headline-three":
      return `
        <div class="paragraph">
          <h3 class="heading-three">
            ${children}
          </h3>
        </div>
      `
    case "headline-four":
      return `
        <div class="paragraph">
          <h4 class="heading-four">
            ${children}
          </h4>
        </div>
      `
    case 'bulleted-list':
      return `<div class="paragraph"><ul>${children}</ul></div>`
    case 'list-item':
      return `<div class="paragraph"><li>${children}</li></div>`
    case 'numbered-list':
      return `<div class="paragraph"><ol>${children}</ol></div>`
    case "heading-one":
      return `<div class="paragraph"><h2 class="heading-one">${children}</h2></div>`;
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "ingress":
      return `<div class="ingress"><p class="newstext">${children}</p></div>`;
    case "paragraph":
      if (children.trim() === "") {
        return `<div class="paragraph"><p class="newstext empty-paragraph">${children}</p></div>`;
      } else {
        return `<div class="paragraph"><p class="newstext">${children}</p></div>`;
      }
    case "link":
      return `<a href="${escapeHtml(item.url)}">${children}</a>`;
    case "image":
      return `<center><img class="newsimg" alt="img" src="${escapeHtml(
        item.url
      )}"/></center>`;
    default:
      return children;
  }
};


const serializeNews = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};

export default withTracker(NewsArticle)
