import ellipse2 from "../../imgs/ellipse2.png";
import React, {useState} from "react";
import {H3, H4} from "../../styles/global-style";
import {
  HiddenImage,
  Img,
  InnerCircle,
  MaskedImageContainer,
  MemberItemCard,
  OuterContainer,
  TeamMemberImg, TitleContainer
} from "./style";

const Member = ({name, title, img, imgOver, body}) => {
  const size = 320;

  const [hovering, setHovering] = useState(false);

  return (
    <MemberItemCard size={size} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <OuterContainer size={size}>
        <InnerCircle size={size}/>
        <MaskedImageContainer ellipse2={ellipse2}>
          <HiddenImage $hovering={hovering}>
            <Img alt="img" src={img}/>
          </HiddenImage>
          <TeamMemberImg className="team-member-img">
            <Img alt="img" src={imgOver}/>
          </TeamMemberImg>
        </MaskedImageContainer>
      </OuterContainer>

      <TitleContainer>
        <H3 fontWeight={600} mb={0}>{name}</H3>
        <H4 fontWeight={500} mb={10} mt={7}>
          {title}
        </H4>
      </TitleContainer>
      <div className="font-neue-haas ">
        {body}
      </div>
    </MemberItemCard>
  );
};

export default Member