import React from "react";
import RecruitCards from "../components/RecruitCards";
import Section from "../components/Section";
import Ring from "../components/Ring";
import Benefits from "../components/Benefits";

import escapeHtml from "escape-html";

import ensemble from "../imgs/ensemble2.png";
import parse from "html-react-parser";
import withTracker from "../hoc/withTracker";
import useJobsResults from "../hook/use-jobs-results";

function Job() {
  const [jobDetails] = useJobsResults();

  return (
    <>
      <Ring top={-180} right={-286}/>
      <Ring top={1500} left={-500} scale={2.6} rotate={100}/>

      <div style={{height: window.innerWidth < 900 ? 200 : 0}}/>

      <Section top={300}>
        <div style={{width: 900, maxWidth: "95%"}}>
          <h1 className="text-white">{jobDetails?.header}</h1>
          <h3 className="font-neue-haas text-white" style={{marginTop: 70,}}>
            <div>
              {jobDetails && parse(
                serialize({children: JSON.parse(jobDetails.paragraph)})
                  .replace(
                    'Linkedin',
                    (
                      `<a 
                          style="color: white" 
                          href="https://www.linkedin.com/company/modoyo/" 
                          target="_blank" rel="noreferrer"
                        >LinkedIn</a>`
                    )
                  )
              )}
            </div>
          </h3>
        </div>
      </Section>

      <RecruitCards/>

      <Section img={ensemble} top={100} right>
        <Benefits/>
      </Section>

      <div style={{height: 100}}></div>
    </>
  );

}

const serialize = (item) => {
  if (item.text !== undefined) {
    let string = escapeHtml(item.text);
    if (item.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (item.italic) {
      string = `<em>${string}</em>`;
    }
    if (item.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = item.children.map((n) => serialize(n)).join("");

  switch (item.type) {
    case "headline-three":
      return `
        <div class="paragraph">
          <h3 class="heading-three">
            ${children}
          </h3>
        </div>
      `
    case "headline-four":
      return `
        <div class="paragraph">
          <h4 class="heading-four">
            ${children}
          </h4>
        </div>
      `
    case 'bulleted-list':
      return `<div class="paragraph"><ul>${children}</ul></div>`
    case 'list-item':
      return `<div class="paragraph"><li>${children}</li></div>`
    case 'numbered-list':
      return `<div class="paragraph"><ol>${children}</ol></div>`
    case "heading-one":
      return `<div class="paragraph"><h2 class="heading-one">${children}</h2></div>`;
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "ingress":
      if (children.trim() === "") {
        return `<div class="ingress"><p class="newstext empty-paragraph">${children}</p></div>`;
      } else {
        return `<div class="ingress"><p class="newstext">${children}</p></div>`;
      }
    case "paragraph":
      if (children.trim() === "") {
        return `<div class="paragraph"><p class="newstext empty-paragraph">${children}</p></div>`;
      } else {
        return `<div class="paragraph"><p class="newstext">${children}</p></div>`;
      }
    case "link":
      return `<a href="${escapeHtml(item.url)}">${children}</a>`;
    case "image":
      return `<center><img class="newsimg" alt="img" src="${escapeHtml(
        item.url
      )}"/></center>`;
    default:
      return children;
  }
}


export default withTracker(Job)