import React, { useState, useEffect, useRef } from "react";

export default function AttachButton({ file, handleFileChange, red }) {
  const [hover, setHover] = useState(false);
  const [hasFile, setHasFile] = useState(false);

  const fileRef = useRef(null);

  useEffect(() => {
    setHasFile(file ? true : false);
  }, [file]);

  return (
    <form style={{ flexGrow: 1 }}>
      <label
        htmlFor="file-upload"
        className="custom-file-upload"
        style={{
          position: "relative",
          fontWeight: hover ? "bold" : "initial",
          float: "right",
          background: red ? "#E512B5" : "#c1ef2c",
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={(e) => {
          if (hasFile) {
            e.preventDefault();
            handleFileChange(null);
            fileRef.current.value = "";
          }
        }}
      >
        {hasFile ? (
          hover ? (
            <i className="far fa-trash-alt" />
          ) : (
            <i className="fas fa-check" />
          )
        ) : (
          "Attach CV"
        )}
      </label>
      <input
        ref={fileRef}
        id="file-upload"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </form>
  );
}
