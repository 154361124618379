import React, {useState, useLayoutEffect, useContext} from "react";
import Section from "../components/Section";
import Ring from "../components/Ring";
import LoadSpinner from "../components/LoadSpinner";
import {Node} from "slate";
import {Theme} from "../utils/Theme";
import {NewsListContainer} from "../components/News/style";
import NewsCard from "../components/News/Card";
import {compareByDate} from "../utils/common";
import {CleanParagraph} from "../components/Admin/News/style";
import withTracker from "../hoc/withTracker";

function News(props) {
  const {setColor} = useContext(Theme);

  const [data, setData] = useState();

  useLayoutEffect(() => {
    setColor("red");
    document.body.style =
      "background: conic-gradient(from 154.76deg at 50% 50%, #CCF029 0deg, #79E23A 64.86deg, #A4E931 176.24deg, #CCF029 360deg);";
    getData();
  }, [setColor]);

  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  function getData() {
    fetch("/.netlify/functions/getposts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((_data) => {
        setData(_data.filter((item) => item.published));
      });
  }

  return (
    <>
      <Ring
        top={280}
        right={-286}
        scale={1.3}
        rotate={40}
        opacity={0.2}
        white
      />
      <Ring
        top={1480}
        left={-200}
        scale={1.3}
        rotate={140}
        opacity={0.2}
        white
      />
      <Ring top={2380} right={-200} scale={1} rotate={10} opacity={0.2} white/>
      <Ring
        top={3000}
        left={-286}
        scale={2}
        rotate={120}
        opacity={0.17}
        white
      />

      <div style={{height: window.innerWidth < 900 ? 150 : 0}}/>

      <Section>
        <h1>News</h1>
        <CleanParagraph className="font-neue-haas" $maxWidth='47rem'>
          Follow our journey from the beginning through our team blog. We'll be sharing insights and lessons we've
          learned while building our first game, drawing upon our past experiences and providing a glimpse into life
          here at Modoyo
        </CleanParagraph>
      </Section>


      {data ? (
        <NewsListContainer>
          {data
            .sort(compareByDate)
            .map((article) => {
              if (!article.newbody) return <></>;

              const content = JSON.parse(article.newbody);

              return (
                <NewsCard
                  key={`article-${article.id}`}
                  text={article.header}
                  body={serialize(content)}
                  page={"/news/" + article.id}
                  image={article.thumb}
                  red
                />
              );
            })}
        </NewsListContainer>
      ) : (
        <LoadSpinner/>
      )}
    </>
  );
}

const serialize = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};

export default withTracker(News)
