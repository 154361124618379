import React, {useContext} from "react";
import {AuthContext} from "../../../utils/AuthContext";
import {Menu} from '@headlessui/react'

import logo from "../../../imgs/Logo_Black.png";
import {
  AdminText,
  ProfilePlaceholder,
  LogoImage,
  MenuButton,
  MenuItem, MenuItemButton, MenuItemLink,
  MenuItems,
  Nav,
  NavLeft,
  NavLink,
  NavRight, ProfileImage,
  UserName
} from "./style";
import {Container} from "../../../styles/global-style";


export default function Navigation() {
  const {user, logout} = useContext(AuthContext);

  return (
    <Container>
      <Nav>
        <NavLeft>
          <NavLink to="/">
            <LogoImage
              alt="logo"
              src={logo}
            />
            <AdminText>ADMIN</AdminText>
          </NavLink>
        </NavLeft>
        <NavRight>
          <NavLink to="/narnia">News</NavLink>
          <NavLink to="/narnia/authors">Authors</NavLink>
          <NavLink to="/narnia/team">Team</NavLink>
          <NavLink to="/narnia/game/edit">Game</NavLink>
          <NavLink to="/narnia/job/edit">Job</NavLink>
          <Menu as="div" className="relative">
            <MenuButton>
              {user?.user_metadata?.profile_url ?
                <ProfileImage src={user?.user_metadata?.profile_url}/>
                :
                <ProfilePlaceholder>
                  {user?.user_metadata?.full_name.substring(0, 1)}
                </ProfilePlaceholder>
              }

              <UserName>
                <div className="full-name">
                  {user?.user_metadata?.full_name}
                </div>
              </UserName>
            </MenuButton>
            <MenuItems>
              <MenuItem>
                {({active}) => (
                  <MenuItemLink to="/narnia/account" className={active ? 'active' : ''}>
                    Account
                  </MenuItemLink>
                )}
              </MenuItem>
              <MenuItem>
                {({active}) => (
                  <MenuItemButton onClick={logout} className={active ? 'active' : ''}>
                    Sign out
                  </MenuItemButton>
                )}
              </MenuItem>
            </MenuItems>
          </Menu>
        </NavRight>
      </Nav>
    </Container>
  );
}
