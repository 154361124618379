import styled from 'styled-components';

export const GameSignUpTest = styled.div`
  & > :not([hidden]) ~ :not([hidden]) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--space-y-reverse));
  }
`

export const Content = styled.div`
  display: flex;
  width: 1050px;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  flex: 1 0 0;
  text-align: left;
  
  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: -5rem;
  }
`

export const Item = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`
