import styled from 'styled-components';


export const Image = styled.img`
  width: ${props => props.$screenWidth ? `${props.$screenWidth}px ` : 'auto'};
  height: ${props => props.$full ? 'auto' : "350px"};
  margin: auto;
  border-radius: 12px;
  transform: ${props => props.$orientation === 'landscape' ? 'rotate(90deg)' : 'landscape'};
  @media (max-width: 640px) {
    width: ${props => props.$w ? props.$w : "270px"};
    height: auto;
  }
`;


export const SliderContainer = styled.div`
  margin-block: 30px;
  padding-inline: 50px;
  margin-bottom: 2rem;
  @media (max-width: 640px) {
    //padding-inline: 30px;
    padding: 0;
  }
`;

export const AllImages = styled.div`
  display: grid;
  padding: 10px;
  margin: 30px;
  border-radius: 12px;
  text-align: center;
  color: white;
  gap: 20px;

  .slick-prev {
    margin-inline: 40px;
  }

  .slick-next {
    margin-inline: 40px;
  }

  img {
    margin: 0;
  }
`;

export const AllImagesContainer = styled.div`
  position: relative;
`;

export const FullScreen = styled.div`
  display: ${props => props.$full ? "flex" : "none"};
  position: fixed;
  background: rgba(0, 0, 0, 0.93);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;

  .slick-prev {
    position: fixed;
    top: 50%;
    left: 0;
  }

  .slick-next {
    position: fixed;
    top: 50%;
    right: 0;
  }

  .slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .image {
    width: ${props => props.$screenHeight && props.$orientation === 'landscape' ? `${props.$screenHeight - 200}px` : '100%'};
    height: ${props => props.$screenWidth && props.$orientation === 'landscape' ? `auto` : ''};
    max-width: ${props => props.$screenWidth && props.$orientation === 'landscape' ? `` : '%'};
    max-height: 100%;
    border-radius: 0;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background: #29BEF0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  z-index: 20;
`