import styled from 'styled-components';
import {Listbox} from "@headlessui/react";


export const HeaderAction = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-block: 2rem;

  .menuitem {
    align-self: center;
  }
`

export const MediaContainer = styled.div`
  @media (min-width: 640px) {
    display: flex;
  }
`;

export const Media = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 640px) {
    margin-bottom: 0;
    margin-right: 1rem;
  }
`

export const Image = styled.img`
  width: ${props => (props.size ? props.size : '8rem')};
  height: ${props => (props.size ? props.size : '8rem')};
  border: 2px solid #000000;
  border-radius: 20px;
  margin-right: 1rem;
  flex-shrink: 0;

  @media (min-width: 640px) {
    margin-right: 1rem;
  }
`;

export const ImagePlaceholder = styled.div`
  width: ${props => (props.size ? props.size : '8rem')};
  height: ${props => (props.size ? props.size : '8rem')};
  border: ${props => (props.$small ? !props.$blackBorder ? "1px solid #ffffff" : '2px solid #000000' : '2px solid #000000')};;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 1rem;
  flex-shrink: 0;
  font-size: ${props => (props.size ? '12px' : '')};
`

export const NewsItem = styled.div`
  border: 2px solid #000000;
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: 20px;
  background: ${props => (props.$published ? '#ededed' : '')};
  transition: all 150ms ease 0s;
`

export const Content = styled.div`
  padding-right: 3rem;
`;

export const Heading = styled.h4`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;

  a {
    color: #000000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Paragraph = styled.p`
  margin-top: 0.25rem;
  margin-left: 0;
  margin-bottom: 0;
  display: -webkit-box;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: ${props => (props.$noHeight ? 'auto' : '72px')};
  z-index: 10;
  font-weight: 500;
  max-width: ${props => (props.$maxWidth ? props.$maxWidth : '')};
`;

export const CleanParagraph = styled.p`
  margin-top: 0.25rem;
  margin-left: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  max-width: ${props => (props.$maxWidth ? props.$maxWidth : '')};
`;

export const LinkedinUrl = styled.a`
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`

export const Icon = styled.div`
  font-size: 18px;
  background: ${props => (props.$delete ? '#000000' : '#15a615')};
  padding: 10px;
  border-radius: 100%;
  color: #ffffff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloseMobile = styled.div`
  font-size: 18px;
  background: rgba(0,0,0,0.8);
  padding: 10px;
  border-radius: 100%;
  color: #ffffff;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 15px;
`

export const Actions = styled.div`
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
`

export const ListBoxContainer = styled.div`
  position: relative;
`

export const ListboxButton = styled(Listbox.Button)`
  position: relative;
  width: 100%;
  cursor: default;
  border-radius: 12px;
  font-size: 18px;
  padding: 15px 20px;
  text-align: left;
  backdrop-filter: ${props => (props.$second ? 'blur(10px)' : '')};
  border: ${props => (props.$second ? '1px solid transparent' : '1px solid #000000')};
  background-color: ${props => (props.$second ? 'rgba(255, 255, 255, 0.667)' : 'transparent')};
  outline: none;
  -webkit-appearance: button;
  background-image: none;
  text-transform: none;
  font-family: inherit;
  height: auto;
`

export const ListboxOptions = styled(Listbox.Options)`
  position: absolute;
  max-height: 300px;
  height: auto;
  width: 100%;
  overflow: auto;
  border-radius: 12px;
  background-color: ${props => (props.$second ? 'rgba(255, 255, 255, 0.667)' : 'black')};
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 1rem;
  box-shadow: ${props => (props.$second ? '0 1px 2px 0 rgba(255, 255, 255, 0.05)' : '0 1px 2px 0 rgba(0, 0, 0, 0.05)')};
  border: ${props => (props.$second ? '1px solid rgba(255, 255, 255, 0.05)' : '1px solid rgba(0, 0, 0, 0.05)')};
  outline: none;
  z-index: 15;
  list-style: none;
  padding-left: 0;
  margin-top: .5rem;
`

export const ListboxOption = styled(Listbox.Option)`
  position: relative;
  cursor: default;
  user-select: none;
  padding: 1rem 3rem;
  margin: 0;
  background-color: ${props => (props.$second ? '' : 'black')};
  color: ${props => (props.$second ? 'black' : 'white')};;

  &:hover {
    background-color: ${props => (props.$second ? 'rgba(0, 0, 0, 0.867)' : 'white')};
    color: ${props => (props.$second ? 'white' : 'black')};

    i {
      color: ${props => (props.$second ? 'white' : 'black')};
    }
  }
`

export const Truncate = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: ${props => (props.$flex ? '1' : '')};
`;

export const IconContainer = styled.span`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 1.25rem;
`;


export const SelectedIcon = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: ${props => (props.$second ? 'black' : 'white')};;;
`

export const TruncatedText = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${props => (props.selected ? 'medium' : 'normal')};
`;

export const Section = styled.div`
  width: ${props => props.width};
`
