import styled, {css} from 'styled-components';
import {FlexJustifyCenter} from "../../styles/global-style";

export const HomeContainer = styled.div`
  @media (max-width: 900px) {
    margin-top: 150px;
  }
`
export const InvestorsTextContainer = styled(FlexJustifyCenter)`
  width: 100%;
  margin-top: 30px;
`

export const WaitingFor = styled(FlexJustifyCenter)`
  width: 100%;
  margin-top: 90px;

  h1 {
    max-width: 90%;
    width: 800px;
    text-align: center;
  }
`

export const InvestorsText = styled.h3`
  text-align: center;
  max-width: 900px
`

export const InlineStyledComponent = styled.div`
  display: inline-block;
  position: relative;
  margin: 10px;
`;

export const StyledInvestorImg = styled.img`
  transition: 150ms ease;
  filter: brightness(0%);
  transform: ${props => (props.$hovering && props.$url ? 'scale(1.05)' : 'scale(1)')};
  cursor: ${props => (props.$url ? 'pointer' : 'initial')};
  height: 240px;
  opacity: 1;
  margin-bottom: ${props => (props.$mt)}
`;

export const InvestorText = styled.div`
  text-align: center;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  bottom: ${props => (props.$hovering ? '5px' : '15px')};
  width: 150%;
  left: 50%;
  transform: translateX(-50%);
  transition: 150ms ease;
  pointer-events: none;
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  z-index: 1;
  margin: 0 110px;
  padding-top: 180px;
  @media (max-width: 900px) {
    margin-inline: 20px;
    flex-direction: column;
    padding-top: 50px;
  }
`

export const SectionText = styled.div`
  max-width: 44%;
  @media (max-width: 900px) {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin-top: -30px;
    text-align: center;
  }
`

export const SectionGraphics = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 44%;
  z-index: 3;

  img {
    position: absolute;
    max-height: 1000px;
    ${props => props.$imageRight ?
            css`
              height: 770px;
              left: 70px;
            ` :
            css`
              right: 70px;
            `}

  }

  @media (max-width: 900px) {
    order: 1;
    width: 100% !important;
    justify-content: center;

    img {
      position: relative;
      width: 80%;
      height: auto;
      left: 0;
      right: 0;
    }
  }
`

export const GameSectionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 765px;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  background-image: url(${props => props.$image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 240px;

  @media (max-width: 900px) {
    height: 554px;
    margin-top: 140px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.4;
`

export const ImageShell = styled.img`
  z-index: 10;
  margin-bottom: 2rem;
  width: ${props => props.big || "55%"};
  @media (max-width: 900px) {
    width: ${props => props.big ? "95%" : "85%"};
  }
`