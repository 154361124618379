import React, { useLayoutEffect, useContext } from "react";
import Section from "../components/Section";
import Ring from "../components/Ring";
import { Theme } from "../utils/Theme";

// import team from "../imgs/NAG-Crew.jpg";
import logoWhite from "../imgs/Logo_White.png";
import logoBlack from "../imgs/Logo_Black.png";
import monogram from "../imgs/monogram_black.png";
import monogram_w from "../imgs/monogram_white.png";
import lvp from "../imgs/lvp.png";
import withTracker from "../hoc/withTracker";

function Press(props) {
  const { setColor } = useContext(Theme);

  useLayoutEffect(() => {
    setColor("red");

    document.body.style =
      "background: conic-gradient(from 154.76deg at 50% 50%, #CCF029 0deg, #79E23A 64.86deg, #A4E931 176.24deg, #CCF029 360deg);";
  }, [setColor]);

  return (
    <>
      <Ring
        top={280}
        right={-286}
        scale={1.3}
        rotate={40}
        opacity={0.2}
        white
      />
      <Ring
        top={1480}
        left={-200}
        scale={1.3}
        rotate={140}
        opacity={0.2}
        white
      />
      <Ring top={2380} right={-200} scale={1} rotate={10} opacity={0.2} white />
      <Ring
        top={3000}
        left={-286}
        scale={2}
        rotate={120}
        opacity={0.17}
        white
      />

      <div style={{ height: window.innerWidth < 900 ? 150 : 0 }} />

      <Section>
        <div style={{ width: 1100, maxWidth: "95%" }}>
          <h1>
            Modoyo raise $1M Pre-Seed Round, led by LVP, to make esports and
            games history
          </h1>
          <h2 className="font-neue-haas" style={{ marginTop: 100, textAlign: "left" }}>
            Modoyo, born out of competitive games development and esports,
            integrates top streamers & pro players to build an ambitious game
            that is made for players, streamers and viewing audiences alike.
          </h2>
          <h2
            style={{
              fontSize: 26,
              fontWeight: 600,
              color: "black",
              textAlign: "left",
              marginBottom: 0,
            }}
          >
            Stockholm, Sweden
          </h2>
          <h3 className="font-neue-haas" style={{ textAlign: "left", marginTop: 0, fontSize: 18 }}>
            Monday Oct 12th, 2022
          </h3>
        </div>
      </Section>
      <Section top={50}>
        <h5
          className="font-neue-haas"
          style={{
            width: 1200,
            maxWidth: "95%",
            fontWeight: 400,
            color: "black",
            textAlign: "left",
            lineHeight: 1.6,
          }}
        >
          Modoyo (previously NAG Studios) has raised $1M in pre-seed funding to
          produce a Super Bowl spectacle for a streaming generation. The round
          was led by games sector titan LVP with participation from Antler.
          Founded by games industry veterans Peter Stahl (DICE & Resolution
          Games), Niklas "Figge" Fegraeus (SEGA, DICE, Fatshark) and Magnus
          Bentling (Avalanche, Mojang, King, Resolution Games) Modoyo is
          dedicated to creating the next generation of competitive experiences
          for gamers. LVP partner Are Mack Growen joins the board as part of the
          investment.
          <br />
          <br />
          “This opportunity, to merge 40+ years of AAA game making experience
          with a deep expertise & network of competitive gaming professionals,
          is incredibly unique and strong.” said Peter Stahl, CEO and
          co-founder, Modoyo. “We aim to build competitive games with a novel
          format, like a digital super bowl that is as exciting to watch as it
          is to play.”
          <br />
          <br />
          “Today's gamers aren't just playing anymore. They are also watching.
          Streamers are the new superstars, but games are still only built to be
          played. Together with streaming and gaming professionals integrated
          into the studio, the game is to be truly made for the new gaming
          culture. As leaders in 30+ AAA game productions during the last 20
          years, including franchises of Battlefield, Medal of Honor, Mirror’s
          Edge, Star Wars Battlefront, Minecraft and more, we have seen over and
          over how one misses the opportunity to properly integrate the
          community into early development and how gamers have gone from being
          only players to also become both viewers and streamers.”
        </h5>
      </Section>
      <Section top={50}>
        <h2 className="font-neue-haas">
          Online gaming has become a rapidly growing culture of competition,
          streaming and broadcasting and yet games are still made to only meet
          the needs of the player.
        </h2>
      </Section>
      <Section top={50}>
        <h5
          className="font-neue-haas"
          style={{
            width: 1200,
            maxWidth: "95%",
            fontWeight: 400,
            color: "black",
            textAlign: "left",
            lineHeight: 1.6,
          }}
        >
          With their first game, Modoyo will integrate community professionals
          (pro-players, teams, broadcasters and streamers) from day one making a
          product truly meant for the new gaming culture. Eight-time Counter
          Strike world champion and founder of the team Ninjas In Pyjamas, Emil
          “HeatoN” Christensen says: “What these guys are building, I want to
          play now. To be integrated as part of the advisory group inside the
          dev team from the beginning is unheard of, and [I have] goosebumps all
          over."
          <br />
          <br />
          “Competitive multiplayer games are the backbone of the modern, online
          gaming industry, and have pushed games into mainstream culture as the
          dominant media form,” said Are Mack Growen, general partner, LVP.
          “Peter, Niklas and Magnus bring complementary backgrounds and unique
          insights – each having intimate knowledge of competitive games, their
          development and community – to provide the next generation of
          competitive, gaming entertainment. Their total entertainment concept
          and community approach sets them apart from anything we’ve seen in the
          space.”
          <br />
          <br />
          With this investment Modoyo intends to selectively add the right
          minded & experienced game makers into their ambitious team. The vision
          is twofold; to alter the course of competitive gaming & esports, both
          as a game and as a show, and build a role model studio in both
          development culture and brand. Find out more at: modoyo.com
          <br />
          <br />
        </h5>
        <h2 style={{ textAlign: "left" }}>About:</h2>
      </Section>

      <div className="pressComps">
        <div className="pressComp">
          <div style={{ textAlign: "center", height: 100 }}>
            <img alt="modyoy" src={logoBlack} style={{ height: 40 }} />
          </div>
          <div style={{ width: "100%" }}>
            <h5
              className="font-neue-haas"
              style={{
                width: 1200,
                maxWidth: "95%",
                fontWeight: 400,
                color: "black",
                textAlign: "left",
                lineHeight: 1.6,
                margin: "30px 0px",
              }}
            >
              Modoyo is based in Stockholm, Sweden. Founded by Peter Stahl, with
              the purpose to alter the future of playing & viewing competitive
              gaming.
              <br />
              <br />
              To learn more, visit:{" "}
              <a
                href="http://www.modoyo.com"
                target="_blank"
                rel="noreferrer"
                style={{ color: "black", textDecoration: "none" }}
              >
                modoyo.com
              </a>
            </h5>
          </div>
        </div>
        <div className="pressComp">
          <div style={{ textAlign: "center", height: 100 }}>
            <img
              alt="lvp"
              src={lvp}
              style={{ height: "100%", filter: "brightness(0)" }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <h5
              className="font-neue-haas"
              style={{
                width: 1200,
                maxWidth: "95%",
                fontWeight: 400,
                color: "black",
                textAlign: "left",
                lineHeight: 1.6,
                margin: "30px 0px",
              }}
            >
              LVP are an early stage fund with a valuable difference: the
              partners are operating experts in the games ecosystem and team
              have been seed investors in game companies that have created over
              $18 billion in value through exits including Supercell, Unity,
              Playfish and NaturalMotion. LVP are investors in many other great
              companies including Singularity 6, AppOnboard, Bunch, Betadwarf,
              Robin Games and others. <br />
              <br />
              To learn more about LVP, visit{" "}
              <a
                href="http://www.londonvp.com"
                target="_blank"
                rel="noreferrer"
                style={{ color: "black", textDecoration: "none" }}
              >
                http://www.londonvp.com
              </a>
              .
            </h5>
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "7%", textAlign: "left" }}>
        <h2 style={{ textAlign: "left" }}>
          For Press Enquiries Please Contact:
        </h2>
      </div>
      <Section top={100}>
        <h1 style={{ marginTop: -90 }}>
          <a
            href="mailto:peter@modoyo.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: "black", textDecoration: "none", fontSize: "7vw" }}
          >
            peter@modoyo.com
          </a>
        </h1>
        <h3>Peter Stahl, CEO & Founder</h3>
      </Section>

      <div
        style={{
          width: "100%",
          marginLeft: "7%",
          textAlign: "left",
          marginBottom: -50,
        }}
      >
        <h2 className="font-neue-haas" style={{ textAlign: "left" }}>Logos:</h2>
      </div>

      <Section top={50}>
        <img src={logoBlack} style={{ width: "40%", padding: 20 }} alt="team" />
        <img src={logoWhite} style={{ width: "40%", padding: 20 }} alt="team" />
        <img src={monogram_w} style={{ height: 400, padding: 20 }} alt="team" />
        <img src={monogram} style={{ height: 400, padding: 20 }} alt="team" />
      </Section>

      <div style={{ height: 100 }} />
    </>
  );
}

export default withTracker(Press)
