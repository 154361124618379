import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #ffff;
  font-size: 20px;
  font-weight: 600;
`

export const IconImage = styled.img`
  width: 20px;
  margin-right: 20px;
  filter: invert(100%);
`;

export const BenefitsHeading = styled.h1`
  color: #ffff;
  margin-top: 0;
  line-height: 1;
  margin-bottom: 45px;
  @media (max-width: 900px) {
    margin-top: 80px;
  }
`;