import React, {useLayoutEffect, useContext} from "react";
import Section from "../components/Section";
import Button from "../components/Button";
import {Theme} from "../utils/Theme";

import {LeanderShipHeadline, MobileContainer} from "../components/LeaderShip/style";
import {Space} from "../styles/global-style";

import leadership1 from "../imgs/leadership/leadership1.png";
import leadership2 from "../imgs/leadership/leadership2.png";
import leadership3 from "../imgs/leadership/leadership3.png";
import leadership4 from "../imgs/leadership/leadership4.png";
import leadership5 from "../imgs/leadership/leadership5.png";
import leadership6 from "../imgs/leadership/leadership6.png";
import withTracker from "../hoc/withTracker";


function Leadership() {
  const {setColor} = useContext(Theme);

  useLayoutEffect(() => {
    setColor("green");

    document.body.style =
      "background: conic-gradient(from 105.27deg at 49.31% 52.18%, #E512B5 -77.85deg, #FA4F0F 108.59deg, #E512B5 282.15deg, #FA4F0F 468.59deg);";
  }, [setColor]);

  return (
    <>
      <div style={{height: window.innerWidth < 900 ? 100 : 0}}/>
      <Section text="Leadership" full>
        <LeanderShipHeadline className="font-neue-haas">
          To work as a leader at Modoyo you agree to act in accordance with the
          following set of leadership principles.
        </LeanderShipHeadline>
      </Section>

      <MobileContainer>
        <Section img={leadership1} top={150} right>
          <h3 className="font-neue-haas">As leaders at Modoyo we act as</h3>
          <h1>Enabling Servants</h1>
          <h2 className="font-neue-haas text-align-left">Caring and unlocking of others will return tenfold.</h2>
        </Section>
      </MobileContainer>

      <MobileContainer>
        <Section img={leadership2} top={210} right>
          <h3 className="font-neue-haas">As leaders at Modoyo we</h3>
          <h1>Listen 80 Talk 20</h1>
          <h2 className="font-neue-haas text-align-left">Create room for hard conversations and great ideas.</h2>
        </Section>
      </MobileContainer>
      <MobileContainer>
        <Section img={leadership3} top={210} right>
          <h3 className="font-neue-haas">As leaders at Modoyo we</h3>
          <h1>Exemplify Vulnerability</h1>
          <h2 className="font-neue-haas text-align-left">
            We courageously own our mistakes and speak them to show that it’s
            okay.
          </h2>
        </Section>
      </MobileContainer>

      <MobileContainer>
        <Section img={leadership4} top={210} right>
          <h3 className="font-neue-haas">A leaders at Modoyo we set and follow</h3>
          <h1>Clear Goals</h1>
          <h2 className="font-neue-haas text-align-left">Unlock autonomous decision making at all levels.</h2>
        </Section>
      </MobileContainer>

      <MobileContainer>
        <Section img={leadership5} top={210} right>
          <h3 className="font-neue-haas">As leaders at Modoyo we</h3>
          <h1>Celebrate Learning</h1>
          <h2 className="font-neue-haas text-align-left">
            Praise succesful failures, right or wrong is really just left or
            right.
          </h2>
        </Section>
      </MobileContainer>

      <MobileContainer>
        <Section img={leadership6} top={210} right>
          <h3 className="font-neue-haas">As leaders at Modoyo we strive to master</h3>
          <h1>The Five Dysfunctions</h1>
          <h2 className="font-neue-haas text-align-left">
            Trust, Disagree, Commit, Hold Accountable and find Results (book by
            Lencioni)
          </h2>
        </Section>
      </MobileContainer>

      <Space margin="0 0 100px 0">
        <Section text="Sound Like You?" full>
          <Button text="Join our team" click="/jobs" black large/>
        </Section>
      </Space>
    </>
  );
}

export default withTracker(Leadership)