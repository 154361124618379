import React, {useState} from "react";
import Slider from "react-slick";
import SlickNextArrow from "../Slick/SlickNextArrow";
import SlickPrevArrow from "../Slick/SlickPrevArrow";

import LeBond from "../../imgs/game/LeBond.jpg";
import map2 from "../../imgs/game/map2.jpg";
import Vesper_01 from "../../imgs/game/Vesper_01.jpg";

import gecko from "../../imgs/Details_Gecko.png";
import {AllImages, AllImagesContainer, CloseButton, FullScreen, Image, SliderContainer} from "./style";
import useWindowSize from "../../hook/use-window-size";
import Button from "../Button";
import {JustifyCenter, Space} from "../../styles/global-style";

const imgs = [
  { name: "Vesper_01", orientation: "landscape", src: Vesper_01 },
  { name: "map2", orientation: "landscape", src: map2 },
  { name: "LeBond", orientation: "portrait", src: LeBond },
  { name: "gecko", orientation: "landscape", src: gecko },
]

function GameSlider() {
  const size = useWindowSize();

  const [showFull, setShowfull] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow/>,
    prevArrow: <SlickPrevArrow/>,
  }

  const onHandleFull = () => {
    setShowfull(!showFull)
  }

  const previousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imgs.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imgs.length - 1 ? 0 : prevIndex + 1
    );
  }

  return (
    size.width <= 768 ?
      <>
        <AllImagesContainer>
          <AllImages onClick={onHandleFull}>
            <div className="slider">
              <SlickPrevArrow className="slick-prev" onClick={previousImage}/>
              <Image className="image" src={imgs[currentImageIndex].src} alt="Slider"/>
              <SlickNextArrow className="slick-next" onClick={nextImage}/>
            </div>
          </AllImages>
        </AllImagesContainer>

        <FullScreen $full={showFull} $orientation={imgs[currentImageIndex].orientation} $screenWidth={size.width} $screenHeight={size.height}>
          <CloseButton onClick={onHandleFull}>
            <i className="fas fa-times"/>
          </CloseButton>
          <div className="slider">
            <SlickPrevArrow className="slick-prev" onClick={previousImage}/>
            <Image className="image" src={imgs[currentImageIndex].src} alt="Slider" $orientation={imgs[currentImageIndex].orientation} $screenWidth={size.height}/>
            <SlickNextArrow className="slick-next" onClick={nextImage}/>
          </div>
        </FullScreen>
      </>
      :
      <>
        <FullScreen $full={showFull}>
          <CloseButton onClick={onHandleFull}>
            <i className="fas fa-times"/>
          </CloseButton>
          <div className="slider">
            <SlickPrevArrow className="slick-prev" onClick={previousImage}/>
            <Image className="image" src={imgs[currentImageIndex].src} alt="Slider"  $full={showFull}/>
            <SlickNextArrow className="slick-next" onClick={nextImage}/>
          </div>
        </FullScreen>
        <SliderContainer>
          <Slider {...settings}>
            {imgs.map((val, i) => {
              return (
                <div key={val.name} style={{ display: "flex", alignItems: "center" }}>
                  <Image
                    alt={"img" + i}
                    src={val.src}
                    $full={showFull}
                  />
                </div>
              );
            })}
          </Slider>
        </SliderContainer>
        <Space margin="3rem 0 3rem 0">
        <JustifyCenter>
          <Button text="Full screen" callback={onHandleFull} lightBlue large/>
        </JustifyCenter>
        </Space>
      </>
  )
}

export default GameSlider