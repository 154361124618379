import React from "react";
import Section from "../components/Section";
import Ring from "../components/Ring";

import ensemble from "../imgs/ensemble.png";

import loader from "../lotties/loader.json";
import Lottie from "react-lottie";
import withTracker from "../hoc/withTracker";
import {H1, H3, Space} from "../styles/global-style";
import Member from "../components/Team/Member";
import {MemberContainer} from "../components/Team/style";
import useTeamResults from "../hook/use-team-results";


function Team() {
  const [team] = useTeamResults()

  return (
    <>
      <Ring top={540} right={-286} scale={0.8} opacity={0.25} white/>
      <Ring top={1540} left={0} scale={1.4} opacity={0.25} white/>

      <Space mt={window.innerWidth < 900 ? 150 : 0}/>

      <Section img={ensemble} top={450} height={800} left>
        <H1 mt={window.innerWidth < 900 ? 70 : 0}>
          The Team
        </H1>
        <H3 className="font-neue-haas">
          Modoyo is building the best possible team who work together to build
          the best possible games. Trust, respect and success is driven by
          working collectively and collaboratively. We are proud of everyone of
          our team members.
        </H3>
      </Section>

      <MemberContainer>
        {team && team.length > 0
          ?
          team.map((item) => (
              <Member
                key={item.full_name}
                img={item.thumb}
                imgOver={item.thumb_hover}
                name={item.full_name}
                title={item.job_title}
                body={item.bio}
                url={item.linkedin_url}
              />
          ))
          :
          <Lottie options={{animationData: loader}} style={{height: 200}}/>
        }
      </MemberContainer>
    </>
  );
}


export default withTracker(Team)