import React, {
  useLayoutEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import {Theme} from "../../../utils/Theme";
import {useParams, useNavigate, Link} from "react-router-dom";
import LoadSpinner from "../../../components/LoadSpinner";
import ModoyoEditor from "../../../components/ModoyoEditor";
import {ImageCropperThumb} from "../../../components/ImageCropperThumb";
import {Container, SpaceY, Title} from "../../../styles/global-style";
import {Input} from "../../../components/Admin/User/Account/style";
import {Listbox} from "@headlessui/react";
import {
  IconContainer, Image, ImagePlaceholder,
  ListboxButton,
  ListBoxContainer, ListboxOption,
  ListboxOptions, Section, SelectedIcon,
  Truncate, TruncatedText
} from "../../../components/Admin/News/style";

export default function Edit() {
  const {setColor} = useContext(Theme);
  const navigate = useNavigate();
  const {idx} = useParams();

  const [data, setData] = useState();
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState({})

  const headerRef = useRef(null);

  const getData = useCallback(() => {
    fetch(`/.netlify/functions/getpost?id=${idx}&published=1`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setSelectedAuthor(data.author)
      });
  }, [idx]);

  useLayoutEffect(() => {
    setColor("red");
    document.body.style =
      "background: conic-gradient(from -85deg at 50% 50%, #ccf029 0deg, #79e23a 64.86deg, #a4e931 176.24deg, #ccf029 360deg);";
    getData();
    getAuthors();
  }, [setColor, idx, navigate, getData]);

  function editPost(header, content, published, thumbUrl, authorId) {
    const _data = {
      id: data.id,
      header: header ? header : null,
      content: content ? mysqlEscape(JSON.stringify(content)) : null,
      published: published === "on" ? 1 : published === "off" ? 0 : -1,
      thumb: thumbUrl || null,
      author_id: authorId ? authorId || -1 : null
    };

    fetch("/.netlify/functions/editpost", {
      method: "POST",
      body: JSON.stringify(_data),
    }).then(() => {
      if (published) getData();
    });
  }

  function mysqlEscape(stringToEscape) {
    if (stringToEscape === "") {
      return stringToEscape;
    }
    return stringToEscape.replace(/'/g, "'").replace(/"/g, '"');
  }

  function getAuthors() {
    fetch("/.netlify/functions/getauthors", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((_data) => {
        setAuthors(_data.filter((item) => item));
      });
  }

  /*async function onFileDelete(id) {
    fetch("/.netlify/functions/destroyimage", {
      method: "POST",
      body: JSON.stringify({ id: id }),
    })
      .then((res) => res.json())
      .then((_data) => {
        console.log(_data);
      });

    // const url = `https://api.cloudinary.com/v1_1/dojgj0dvl/destroy`;
    //
    // var xhr = new XMLHttpRequest();
    // var fd = new FormData();
    // xhr.open("POST", url, true);
    // xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    //
    // xhr.onreadystatechange = fileDeleted.bind(this, xhr);
    // fd.append("public_id", id);
    // xhr.send(fd);
  }

  async function fileDeleted(xhr) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      var response = JSON.parse(xhr.responseText);
      console.log(response);
    }
  }*/
  const onChangeAuthor = (value) => {
    setSelectedAuthor(value)
    editPost(data.header, null, data.published, null, value.id)
  }

  const removeAuthor = () => {
    setSelectedAuthor({})
    editPost(data.header, null, data.published, null, -1)
  }

  return !data ? (
    <LoadSpinner/>
  ) : (
    <Container>
      <div style={{marginBottom: "1rem", color: "black"}}>
        <Link to="/narnia/news" style={{
          color: "black",
          textDecoration: "none",
          alignItems: "center",
          display: "flex",
          marginBottom: "2rem",
          marginTop: "1rem"
        }}><i
          className="fas fa-arrow-left" style={{marginRight: ".5rem", fontSize: "18px"}}/>Back</Link>
        <Title>News Details</Title>
      </div>
      <SpaceY number="1.5rem">
        <div>
          <ImageCropperThumb
            editPost={editPost}
            thumb={data.thumb}
            published={data.published}
            authorId={authors.id}
          />
        </div>

        <div
          style={{width: "100%", display: "flex", flexDirection: "column"}}>
          <label>Title</label>
          <Input
            ref={headerRef}
            className="input"
            defaultValue={
              data && data.header && data.header !== "null"
                ? data.header
                : ""
            }
            placeholder="Title here..."
            onChange={(e) => {
              editPost(e.target.value);
            }}
          />
        </div>
        <div>
          <label>Author</label>
          <Section width="400px">
            <Listbox by="id" value={selectedAuthor} onChange={onChangeAuthor}>
              <ListBoxContainer>
                <ListboxButton>
                  <Truncate>
                    {selectedAuthor?.profile_url ?
                      <Image size="1.65rem" src={selectedAuthor.profile_url}/>
                      :
                      null
                    }
                    {selectedAuthor?.full_name ?
                      <Truncate $flex>
                        <Truncate style={{flex: 1, marginRight: "1rem"}}>
                          {selectedAuthor?.full_name}
                        </Truncate>
                        <span style={{
                          background: "black",
                          color: "white",
                          borderRadius: "100%",
                          width: 27,
                          height: 27,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: 12,
                          marginRight: "2rem"
                        }}
                              onClick={removeAuthor}
                        >
                          <i className="fas fa-times"/>
                        </span>
                      </Truncate>
                      :
                      'Select Author'}
                  </Truncate>
                  <IconContainer>
                    <i className="fas fa-chevron-down"/>
                  </IconContainer>
                </ListboxButton>

                <ListboxOptions>
                  {authors.map((author, personIdx) => (
                    <ListboxOption
                      key={author.id}
                      value={author}
                    >
                      {({selected}) => (
                        <>
                          <TruncatedText selected={selected}>
                            {author.profile_url ?
                              <Image size="2rem" src={author.profile_url}/>
                              :
                              <ImagePlaceholder size="2rem" $small>{author.full_name.substring(0, 1)}</ImagePlaceholder>
                            }
                            {author.full_name}
                          </TruncatedText>
                          {selected ? (
                            <SelectedIcon>
                              <i className="fas fa-check"/>
                            </SelectedIcon>
                          ) : null}
                        </>
                      )}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </ListBoxContainer>
            </Listbox>
          </Section>
        </div>
        <div style={{display: "inline-block", width: "100%", marginBottom: "5rem"}}>
          {data && (
            <ModoyoEditor
              text={data.newbody}
              update={editPost}
              published={data.published}
            />
          )}
        </div>
      </SpaceY>
    </Container>
  );
}
