import React, {useLayoutEffect, useContext} from "react";
import Section from "../components/Section";
import Ring from "../components/Ring";
import {Theme} from "../utils/Theme";
import withTracker from "../hoc/withTracker";

function PrivacyPolicy() {
  const {setColor} = useContext(Theme);

  useLayoutEffect(() => {
    setColor("green");

    document.body.style = "background: #EDEDED;";
  }, [setColor]);

  return (
    <div className="font-neue-haas">
      <Ring top={80} left={-206} scale={1.3} rotate={220} opacity={0.3} white/>
      <Ring
        top={1200}
        right={-286}
        scale={2}
        rotate={180}
        opacity={0.3}
        white
      />
      <Ring top={2200} left={-186} scale={1} rotate={160} opacity={0.3} white/>
      <Ring
        top={3000}
        right={-130}
        scale={1.8}
        rotate={130}
        opacity={0.3}
        white
      />
      <div style={{height: 150}}/>
      <Section>
        <h1>Privacy Policy for Modoyo</h1>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          MODOYO SAFEGUARDS YOUR PERSONAL INTEGRITY
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Modoyo AB, corp. reg. no. 559252-7625, Tjärhovsgatan 34, 116 21 Stockholm, Sweden, (<b>”Modoyo”</b>) respects your
          privacy and is committed to maintaining a high level of security and integrity regarding your personal data.
          Modoyo is also committed to ensuring that processing is carried out in accordance with applicable data
          protection legislation.
        </p>
        <p>
          This Privacy Policy describes how Modoyo processes personal data about
          you in your capacity as
        </p>

        <ul>
          <li>
            a customer or user accessing and/or using Modoyo’s games, products
            and services, or a potential customer or user,
          </li>
          <li>
            a representative of a company that is a supplier or a partner, or a
            potential supplier or partner to Modoyo,
          </li>
          <li>
            a visitor of Modoyo’s website{" "}
            <a href="https://www.modoyo.com/" target="_blank" rel="noreferrer">
              https://www.modoyo.com/
            </a>
            ,
          </li>
          <li>an applicant when you apply for employment with Modoyo, and</li>
          <li>
            a shareholder or as a representative of a shareholder or as a holder
            or a representative of a holder of other financial instruments that
            can be converted to or that entitle to subscribe for shares in
            Modoyo (“<b>Shareholder</b>”).
          </li>
        </ul>
        <p>
          Do not hesitate to contact us should you have any questions regarding
          our privacy protection. Modoyo’s contact information is set out under
          the section “Contact Information”.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>CONTROLLER</h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Modoyo is the controller of your personal data and is therefore
          responsible for ensuring that your personal data is processed
          correctly and securely in accordance with applicable legislation.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          WHICH PERSONAL DATA DOES MODOYO PROCESS?
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Personal data means any information that directly or indirectly
          relates to a natural, living person. Accordingly, personal data is
          information about you and your person, e.g. your name, your contact
          information, pictures of you and your IP-address.
        </p>
        <p>
          Processing means any operation which is performed on personal data,
          such as collection, storage, use, adaption or disclosure.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          Customers and users of our games, products and services
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Modoyo collects and stores the following information about you that
          Modoyo needs to contact you and to fulfil its undertakings toward you
          as a customer and user of Modoyo’s games, products and services.
        </p>
        <p>
          The provision of certain personal data to us described in this privacy
          policy may be a statutory or contractual requirement under a contract
          to which you are party. If you do not share such personal data with
          Modoyo, Modoyo may not be able to fulfil its legal or contractual
          obligations towards you.
        </p>
        <ul>
          <li>
            <b>Personal information and contact information</b> that can be used
            to identify you, such as name, personal identity number, address,
            telephone number and email address.
          </li>
          <li>
            <b>Account information</b> meaning information that is associated
            with your customer profile at Modoyo, such as usernames, passwords,
            security questions and what games, products and services you use
            and/or have access to.
          </li>
          <li>
            <b>Payment information</b> such as your payment and transaction
            history with Modoyo, your payment information e.g., credit or debit
            card information, bank information, account number and, where
            applicable, IBAN, SWIFT etc.
          </li>
          <li>
            <b>Information in communications with Modoyo,</b> information that
            you provide to Modoyo by email, via Modoyo’s social media or by
            other channels of communication and, where applicable, information
            regarding phone calls with Modoyo’s switchboard or customer service.
          </li>
          <li>
            <b>Customer satisfaction information,</b> such as information from
            customer surveys and evaluations, customer complaints, support or
            warranty matters.
          </li>
          <li>
            <b>Usage and behavioural information,</b> such as information about
            how you use or intend to use our games, products and services
            including information about time spent playing and how and when you
            have accessed and interacted with our games or services as well as
            technical information such as errors, bugs or technical performance.
            <p>
              This information is collected to allow Modoyo to better understand
              which features and services are most engaging, relevant and useful
              to our customers and users and to communicate with you about
              relevant and up-to-date information and marketing.
            </p>
          </li>
          <li>
            <b>Device information,</b> such as which device and other technology
            has been used to access our games, products and services, including
            your internet protocol (IP) address, MAC Address, URL, unique device
            ID as well as your login data, network ID, network and device
            performance, browser type and version, hardware information, time
            zone settings, language settings, geographical location, browser
            plug-in types and versions, operating system and other technology on
            the device you use as well as other data from cookies or similar
            mechanisms. In cases of a malfunction, error or interruption on your
            device, technical data may also include specific device information,
            such as the location of the file on your device.
          </li>
          <li>
            <b>Events and meetings,</b> where applicable, information regarding
            dietary preferences, potential allergies and/or disabilities (e.g.
            in connection with corporate events or meetings).
          </li>
        </ul>
        <p>
          With respect to our customers and users, your personal data is usually
          provided directly from you, but also, in certain cases, through other
          sources such as our suppliers and partners.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          Company representatives for e.g. suppliers and partners
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Modoyo collects and stores the following information about you that
          Modoyo needs to contact you in your capacity as a representative for a
          company or organisation.
        </p>
        <ul>
          <li>
            <b>Personal information and contact information,</b> such as name,
            address, telephone number, email address, title, position and
            employer.
          </li>
          <li>
            <b>Information in communications with Modoyo,</b> information that
            you provide to Modoyo by email, via Modoyo’s social media or by
            other channels of communication and, where applicable, information
            regarding phone calls with Modoyo’s switchboard or customer service.
          </li>
          <li>
            <b>Supplier or partner agreements,</b> certain personal data
            included in agreement with our suppliers and partners, for example
            contact information to designated contact persons or names of the
            individuals signing the agreements.
          </li>
          <li>
            <b>Events and meetings,</b> where applicable, information regarding
            dietary preferences, potential allergies and/or disabilities (e.g.
            in connection with corporate events or meetings).
          </li>
        </ul>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          Potential customers, suppliers and partners and company
          representatives for potential suppliers and partners
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Modoyo collects and stores the following information about you in your
          role as a potential customer, supplier or partner to Modoyo or in your
          role as a representative for a company that is a potential supplier or
          partner to Modoyo. Modoyo needs such information to contact you and to
          fulfil its undertakings toward you as a potential customer, supplier
          or partner or in your capacity as a representative for a potential
          supplier or partner.
        </p>
        <ul>
          <li>
            <b>Personal information and contact information,</b> such as name,
            address, telephone number, email address, title, position and
            employer.
          </li>
        </ul>
        <p>
          With respect to suppliers and partners and company representatives for
          suppliers and partners and potential suppliers and partners and
          company representatives for potential suppliers and partners, your
          personal data is usually collected from the company or organisation
          that you represent, but also, in certain cases, directly from you,
          e.g. by email, our social media or other channels of communication or
          in connection with events or meetings. Modoyo may also receive
          personal data about you from other partners of Modoyo. Modoyo may also
          use external information services to supplement existing data, e.g.
          with your position and contact information.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>Visitors of Modoyo’s website</h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          In connection with visits to Modoyo’s website, Modoyo collects the
          following information about you that Modoyo needs to be able to
          improve, streamline, simplify and develop our website.
        </p>
        <ul>
          <li>
            <b>Technical data and device information,</b> such as IP address,
            MAC address, URL, unique device ID, network and device performance,
            browser, language and identification settings, geographic location,
            operating system, other information from cookies or similar
            mechanisms (device information).
          </li>
        </ul>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          Recruitment (job applicants at Modoyo)
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Modoyo collects and stores the following information about you that
          Modoyo needs to be able to recruit the right persons for positions
          with Modoyo.
        </p>
        <ul>
          <li>
            <b>Personal information and contact information,</b> such as name,
            address, telephone number, email address, title, position and
            employer.
          </li>
          <li>Information in cover letter and CV.</li>
          <li>Where applicable, your picture.</li>
          <li>
            Other information that you provide Modoyo in connection with
            recruitment.
          </li>
        </ul>
        <p>
          With respect to job applicants, your personal data may be provided to
          Modoyo directly from you or from the staffing agency, recruitment
          company or hiring company through which you apply for work.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>Shareholders</h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Modoyo collects and stores the following information about you in your
          role as a Shareholder.
        </p>
        <ul>
          <li>
            <b>Personal information and contact information,</b> such as
            personal identification number, name, address, telephone number,
            email address, and position.
          </li>
          <li>Holdings of shares or other financial instruments.</li>
          <li>
            <b>Information on financial instruments,</b> e.g. information
            regarding voting rights at general meetings of shareholders,
            shareholding and rights relating to the ownership.
          </li>
          <li>
            Information regarding proxies representing Shareholders, where
            applicable.
          </li>
          <li>
            Information regarding trustees, pledges and pledgees, where
            applicable, and other notes in the share register.
          </li>
          <li>
            Other information that has been provided by you or the organisation
            that you represent.
          </li>
        </ul>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          WHAT IS THE PURPOSE AND LEGAL BASIS FOR MODOYO’S PROCESSING OF YOUR
          PERSONAL DATA?
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          The purposes for which we intend to process your personal data and the
          legal basis for the respective processing activities are stated in the
          tables below.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          Customers and users of our games, products and services
        </h2>
      </Section>
      <Section top={1}>
        <table
          style={{
            width: 900,
            maxWidth: "95%",
            margin: "0px auto",
            marginTop: 40,
            textAlign: "left",
          }}
        >
          <tbody>
          <tr>
            <th style={{width: "50%"}}>
              <p>Purpose</p>
            </th>
            <th style={{width: "50%"}}>
              <p>Legal basis</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>
                To register and administrate a customer profile and to be able
                to offer a safe, secure and legal service.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for the performance of the
                agreement with the customer.
              </p>
              <p>
                The processing is necessary for compliance with Modoyo’s legal
                obligations.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To be able to keep in touch with the customer, either through
                Modoyo’s games, products and services or the customer support.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for the performance of the
                agreement with the customer.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To fulfil legal requirements, e.g. security requirements and
                accounting requirements.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for compliance with Modoyo’s legal
                obligations.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To enable marketing and communication about Modoyo's brand and
                Modoyo’s games, products and services (e.g. mailing of
                newsletters and other marketing materials, invitations to
                Modoyo's events, meetings and other gatherings etc.).
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for Modoyo’s legitimate interest
                to market its brand, games, products and services and other
                similar products and services to you as a customer (legitimate
                interest).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To carry out surveys regarding Modoyo’s games, products and
                services as well as customers’ experiences and satisfaction.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for Modoyo’s legitimate interest
                to evaluate, develop and improve its brand, its products and
                its marketing (legitimate interest).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To ensure payment and analyse purchase history in order to
                offer customers the right products and marketing.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for the performance of the
                agreement with the customer.
              </p>
              <p>
                The processing is necessary for Modoyo’s legitimate interest
                to offer its customers relevant marketing with regard to the
                customers previous purchases (legitimate interest).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To receive payments from customers in connection with
                purchases of Modoyo’s games, products and services.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for the performance of the
                agreement with the customer.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To be able to respond to and compensate customers in
                connection with complaints and warranty matters.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for the performance of the
                agreement with the customer.
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </Section>

      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          Potential future customers, suppliers or partners
        </h2>
      </Section>
      <Section top={1}>
        <table
          style={{
            width: 900,
            maxWidth: "95%",
            margin: "0px auto",
            marginTop: 40,
            textAlign: "left",
          }}
        >
          <tbody>
          <tr>
            <th style={{width: "50%"}}>
              <p>Purpose</p>
            </th>
            <th style={{width: "50%"}}>
              <p>Legal basis</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>
                To enable marketing and communication about Modoyo's brand and
                Modoyo's games, products and services (e.g. mailing of
                newsletters and other marketing materials, invitations to
                Modoyo's events, meetings and other gatherings etc.).
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for Modoyo’s legitimate interest
                to market its brand, games, products and services and other
                similar products and services to you as a customer (legitimate
                interest).
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>Visitors of Modoyo’s website</h2>
      </Section>
      <Section top={1}>
        <table
          style={{
            width: 900,
            maxWidth: "95%",
            margin: "0px auto",
            marginTop: 40,
            textAlign: "left",
          }}
        >
          <tbody>
          <tr>
            <th style={{width: "50%"}}>
              <p>Purpose</p>
            </th>
            <th style={{width: "50%"}}>
              <p>Legal basis</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>
                To ensure the operation of Modoyo's website and application.
              </p>
              <p>
                To ensure the operation of Modoyo's website and application.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for Modoyo’s legitimate interest
                to improve, streamline, simplify and develop its website and
                to attract more customers/partners and to increase the number
                of recurring customers/partners (legitimate interest).
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          Recruitment (job applicants at Modoyo)
        </h2>
      </Section>
      <Section top={1}>
        <table
          style={{
            width: 900,
            maxWidth: "95%",
            margin: "0px auto",
            marginTop: 40,
            textAlign: "left",
          }}
        >
          <tbody>
          <tr>
            <th style={{width: "50%"}}>
              <p>Purpose</p>
            </th>
            <th style={{width: "50%"}}>
              <p>Legal basis</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>
                To, in connection with recruitment, be able to decide who is
                best suited for a position with Modoyo and to ensure that the
                relevant person has the necessary skills.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary for Modoyo’s legitimate interest
                to recruit the right employees and ensure that skilled people
                work for Modoyo (legitimate interest).
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>Shareholders</h2>
      </Section>
      <Section top={1}>
        <table
          style={{
            width: 900,
            maxWidth: "95%",
            margin: "0px auto",
            marginTop: 40,
            textAlign: "left",
          }}
        >
          <tbody>
          <tr>
            <th style={{width: "50%"}}>
              <p>Purpose</p>
            </th>
            <th style={{width: "50%"}}>
              <p>Legal basis</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>
                To fulfil Modoyo’s obligations to you as a Shareholder
                according to Modoyo’s articles of association.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary to fulfil Modoyo's contractual
                obligations to you in accordance with the articles of
                association (performance of agreement).
              </p>
              <p>
                Regarding representatives of Shareholders, the processing is
                necessary for Modoyo's legitimate interest in fulfilling its
                contractual obligations towards its Shareholders (legitimate
                interest).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                To fulfil Modoyo's obligations to you and other Shareholders
                in accordance with the Swedish Companies Act or other
                applicable legislation.
              </p>
            </td>
            <td>
              <p>
                The processing is necessary to fulfil Modoyo's legal
                obligations under the Swedish Companies Act or other
                applicable legislation (legal obligations).
              </p>
              <p>
                Regarding representatives of Shareholders, the processing is
                necessary for Modoyo's legitimate interest in fulfilling its
                contractual obligations towards its Shareholders (legitimate
                interest).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Distribution of shareholder-related information</p>
            </td>
            <td>
              <p>
                The processing is necessary in order for Modoyo to be able to
                inform Shareholders and other stakeholders in a timely and
                correct manner about information relevant to Modoyo
                (legitimate interest).
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          HOW LONG DOES MODOYO STORE YOUR PERSONAL DATA?
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Your personal data is stored as long as there is a need to preserve
          them in order to fulfil the purposes for which the data was collected
          in accordance with this Privacy Policy. Thereafter, your personal data
          will be deleted.
        </p>
        <p>
          Some personal data will, for the purpose of complying with applicable
          accounting legislation, be stored for seven years, counting from the
          end of the calendar year during which the financial year, to which the
          information pertained, was terminated.
        </p>
        <p>
          Contact information regarding company representatives is stored during
          such time Modoyo considers that the information is necessary to
          maintain the relationship with the company/organisation. Deletion
          shall take place when Modoyo becomes aware that the information is no
          longer adequate or relevant for the purpose, or at the request of the
          contact person.
        </p>
        <p>
          For more information about how long Modoyo stores specific personal
          data, please contact Modoyo. Contact information is provided under
          section "Contact" below.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          WITH WHOM DOES MODOYO SHARE YOUR PERSONAL DATA?
        </h2>
      </Section>
      <Section top={1}>
        <p>
          The company does not disclose personal data to third parties, except
          when necessary to fulfil a legal obligation or to fulfil Modoyo's
          obligations to you, customers and/or partners. Your personal data will
          not be sold to third parties for marketing purposes. Situations when
          your personal data may be disclosed to third parties are listed in the
          table below.
        </p>
        <table
          style={{
            width: 900,
            maxWidth: "95%",
            margin: "0px auto",
            marginTop: 40,
            textAlign: "left",
          }}
        >
          <tbody>
          <tr>
            <th style={{width: "50%"}}>
              <p>Third party</p>
            </th>
            <th style={{width: "50%"}}>
              <p>Reason for third-party disclosure</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>Suppliers of cloud solutions</p>
            </td>
            <td>
              <p>
                Personal data may be transferred to suppliers of cloud
                solutions since Modoyo stores certain information in cloud
                solutions.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Suppliers and partners</p>
            </td>
            <td>
              <p>
                Modoyo may disclose your personal data to suppliers and/or
                partners, if the suppliers and/or partners need your personal
                data to fulfil their undertakings toward Modoyo.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Authorities</p>
            </td>
            <td>
              <p>
                Personal data may be disclosed to authorities when necessary
                for compliance with Modoyo’s legal obligations.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Sale</p>
            </td>
            <td>
              <p>
                If Modoyo intends to transfer all or part of its business,
                personal data may be disclosed to a potential buyer.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>The public</p>
            </td>
            <td>
              <p>
                Modoyo's share register is public and held available at Modoyo
                for anyone who wishes to take part of it. The information
                contained in the share register is therefore disclosed upon
                request.
              </p>
              <p>
                Minutes and voting list from the general meetings of
                shareholders may be disclosed to the Shareholders present at
                the meeting as well as to the Swedish Companies Registration
                Office, auditors and others to whom Modoyo has a legal
                obligation to disclose the minutes or otherwise considers it
                appropriate to disclose the minutes.
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          TRANSFER OF PERSONAL DATA TO THIRD COUNTRIES
        </h2>
      </Section>
      <Section top={1}>
        <p>
          Modoyo may transfer your personal data to countries outside the
          EU/EEA. If your personal data is transferred to a country outside the
          EU/EEA, Modoyo will take the necessary measures to ensure that the
          transfer of the personal data is legal and that your personal data is
          processed securely and with an adequate level of protection that is
          comparable to the protection offered within the EU/EEA, for example by
          entering into the European Commission’s standard contract clauses with
          the recipient. If you wish to obtain a copy of the standard
          contractual clauses, please contact us. Modoyo’s contact information
          is set out under the section “Contact Information” below).
        </p>
        <p>
          The table below shows to which countries Modoyo may transfer your
          personal data and the relevant statutory safeguard relied on.
        </p>
        <table
          style={{
            width: 900,
            maxWidth: "95%",
            margin: "0px auto",
            marginTop: 40,
            textAlign: "left",
          }}
        >
          <tbody>
          <tr>
            <th style={{width: "50%"}}>
              <p>Country outside the EU</p>
            </th>
            <th style={{width: "50%"}}>
              <p>Statuatory safeguard</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>USA</p>
              <p>
                (For example, our suppliers and partners AWS, Qualtrics and G
                Suite or Google Workspace)
              </p>
            </td>
            <td>
              <p>
                The standard data protection clauses adopted by the EU
                Commission, GDPR Article 46.2 (c).
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>SOCIAL MEDIA</h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Regarding personal data that occurs and is processed on social media,
          such as Discord, Facebook, Twitter, LinkedIn, Reddit, TikTok,
          Instagram, and Youtube, we refer users to the policy provided by the
          respective service providers for information on how each service
          provider processes personal data. In Modoyo’s view, the purpose of the
          processing is that representatives of existing and potential customers
          and partners to Modoyo shall be able to interact and maintain contact
          with Modoyo via social media, in order to contribute to good
          relationships with customers and partners and to make Modoyo's
          customer service and product widely accessible through several
          different channels. The processing is necessary for the purposes of
          Modoyo’s legitimate interest to market its brand and its products to
          existing and potential customers and to partners (legitimate
          interest).
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>YOUR RIGHTS</h2>
      </Section>
      <Section top={1}>
        <table
          style={{
            width: 900,
            maxWidth: "95%",
            margin: "0px auto",
            marginTop: 40,
            textAlign: "left",
          }}
        >
          <tbody>
          <tr>
            <td style={{width: "50%"}}>
              <p>Right of access </p>
            </td>
            <td style={{width: "50%"}}>
              <p>
                You have the right to access your personal data and to obtain
                a copy of the personal data concerning you that is processed
                by Modoyo.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Right to rectification</p>
            </td>
            <td>
              <p>
                If the personal data concerning you that is processed by
                Modoyo is inaccurate, incomplete or outdated, you have the
                right to obtain rectification of such personal data.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Right to erasure</p>
            </td>
            <td>
              <p>
                You have the right to request the erasure of personal data
                concerning you. Unless Modoyo has a legal basis to continue
                the processing of the personal data concerning you, such
                personal data shall be erased.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Right to object</p>
            </td>
            <td>
              <p>
                Under certain circumstances you have the right to object
                against Modoyo’s processing of your personal data.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Right to restriction of processing</p>
            </td>
            <td>
              <p>
                Under certain circumstances you have the right to obtain
                restriction of the processing of your personal data. Where
                processing has been restricted, Modoyo may only under certain
                circumstances carry out other processing activities concerning
                the personal data than storage.{" "}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Right to data portability</p>
            </td>
            <td>
              <p>
                Where your personal data is processed based on your consent or
                on a contract with you, you have the right to receive the
                personal data concerning you in a machine-readable format and
                request that those data are transmitted to another controller.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Right to lodge complaints with a supervisory authority</p>
            </td>
            <td>
              <p>
                You have the right to lodge complaints concerning Modoyo’s
                processing of the personal data concerning you to the Swedish
                Authority for Privacy Protection, Box 8114, SE-104 20
                Stockholm.
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>SECURITY OF YOUR PERSONAL DATA</h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          You should always be able to feel safe when you provide us with your
          personal data. Therefore, Modoyo has implemented the security measures
          that are necessary to protect your personal data against unauthorised
          access, alteration and destruction. Modoyo will not disclose your
          personal data, other than as expressly provided by this Privacy
          Policy.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>COOKIES</h2>
      </Section>
      <Section top={1} noncenter>
        <p>Modoyo does not use cookies on our website or in our services.</p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          ADVERTISING PARTNERS’ AND OTHER THIRD PARTIES’ PRIVACY POLICIES
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on Modoyo, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>
        <p>
          Note that Modoyo has no access to or control over these cookies that
          are used by third-party advertisers.
        </p>
        <p>
          Modoyo's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective privacy
          policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
        </p>
        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>CHILDRENS’ INFORMATION</h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p>
          Modoyo does not knowingly collect any personal data from children
          under the age of 13. If you think that your child provided this kind
          of information on our website, we strongly encourage you to contact us
          immediately and we will do our best efforts to promptly remove such
          information from our records.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>
          IF YOU DO NOT SHARE YOUR PERSONAL DATA WITH THE COMPANY
        </h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          If you do not share your personal data with Modoyo, Modoyo will not be
          able to fulfil its legal or contractual obligations towards you. For
          Shareholders, this means that you will not be able to exercise your
          rights as a Shareholder, including attending and voting at general
          meetings.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>CHANGES</h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Modoyo reserves the right to change this Privacy Policy at any time.
          In the event of changes to this Privacy Policy, Modoyo will publish
          the amended Privacy Policy on{" "}
          <a href="https://www.modoyo.com/" target="_blank" rel="noreferrer">
            https://www.modoyo.com/
          </a>{" "}
          with information on when the changes will come into effect and may
          also notify customers and partners in an appropriate manner.
        </p>
      </Section>
      <Section top={30}>
        <h2 style={{marginBottom: 6}}>CONTACT INFORMATION</h2>
      </Section>
      <Section top={1} noncenter>
        <p>
          Do not hesitate to contact Modoyo if you have any questions about this
          Privacy Policy, the processing of your personal data or if you wish to
          exercise your rights under this Privacy Policy or applicable
          legislation.
        </p>
        <br/>
        <br/>
        <p>Modoyo AB</p>
        <p>Corporate registration number: 559252-7625</p>
        <p>Postal address: Tjärhovsgatan 34, 116 21 Stockholm, Sweden</p>
        <p>
          Email address:{" "}
          <a href="mailto:privacy@modoyo.com">privacy@modoyo.com</a>
        </p>
      </Section>
      <div style={{height: 90}}></div>
    </div>
  );
}

export default withTracker(PrivacyPolicy)
