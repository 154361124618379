import styled from 'styled-components';

export const SlickArrowsContainer = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  padding: 15px;
  border-radius: 50%;
  background: #29BEF0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);

  @media (max-width: 640px) {
    padding: 10px;
    width: 20px;
    height: 20px;
    margin-inline: 15px;
  }
  z-index: 100;

  &:hover, &:focus {
    background: #1591bb;
  }
`;

export const SlickArrowsImage = styled.img`
  width: 20px;
  height: 20px;
  padding-right: 5px;
  transform: ${props => (props.$next ? 'rotate(180deg)' : 'rotate(0)')};
  @media (max-width: 640px) {
    width: 10px;
    height: 10px;
  }
`;

