import styled from 'styled-components';

export const MobileContainer = styled.div`
  @media (max-width: 900px) {
    background: #ffffff40;
    border-radius: 12px;
    padding: 20px;
    margin: 20px;
    .section{
      padding-top: 0 !important;
    }
  }
`

export const LeanderShipHeadline = styled.h2`
  margin-top: -20px;
  font-size: 20px;
`


