import styled from 'styled-components';

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 152px;
  min-height: 40px;
  cursor: ${(props) => (props.$disabled ? "initial" : "pointer")};
  transform: ${(props) => (props.$large ? "scale(0.9)" : "none")};
  margin: ${(props) => props.$menuitem ? "0px 20px" : props.$large ? "25px 0px" : 0};
  user-select: ${(props) => (props.disabled ? "none" : "default")};
`;

export const ButtonBackground = styled.div`
  position: absolute;
  width: ${props => (props.$hovering ? "109%" : "100%")};
  height: ${props => (props.$hovering ? "103%" : "100%")};
  background: ${props =>
  props.$background
    ? props.$background
    : props.$hovering
      ? "#00000033"
      : "transparent"};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 150ms ease;
  border-radius: ${props => (props.$large ? "10px" : "8px")};
  box-shadow: ${props =>
  props.$background !== "none"
    ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
    : "none"};
`;


export const ButtonInside = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${props => (props.$large ? '22px 45px 18px 45px' : '3px 36px 0px 36px')};
  font-size: ${props => (props.$large ? 21 : 13)}px;
  z-index: 10;
  user-select: none;
  color: ${props => props.$color};
  font-family: termina;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
  opacity: ${props => (props.$disabled ? 0.5 : 1)};
  transition: 150ms ease;
  transform: ${props => (props.$color === 'none' && props.$hovering ? 'scale(1.2)' : 'none')};
  filter: ${props => (props.$invert ? `invert(${props.$invert})` : 'none')};
`;
