import styled from 'styled-components';
import {Popover} from "@headlessui/react";

export const NewsListContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 546px 546px;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 100px;
  @media (max-width: 640px) {
    grid-template-columns: 1fr; /* Switch to a single column layout for mobile devices */
    padding-inline: 25px;
  }
`

export const ProfileImage = styled.img`
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
  align-items: center;
  color: #ffffff;
  background-color: #000000;
  border-radius: 9999px;
  text-transform: uppercase;
  margin-right: 1rem;

  @media (max-width: 640px) {
    height: 3rem;
    width: 3rem;
  }
`;

export const ProfilePlaceholder = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
  align-items: center;
  color: #ffffff;
  background-color: #000000;
  border-radius: 9999px;
  text-transform: uppercase;
  margin-right: 1rem;
  @media (max-width: 640px) {
    height: 3rem;
    width: 3rem;
  }
`;

export const PopoverContainer = styled(Popover)`
  position: relative;
`;

export const PopoverPanel = styled(Popover.Panel)`
  position: absolute;
  //left: 50%;
  z-index: 10;
  margin-top: 1rem;
  width: 100vw;
  max-width: 20rem;
  transform: translateX(-50%);
  @media (min-width: 640px) {
    padding: 0;
  }
  @media (min-width: 1024px) {
    max-width: ${props => (props.width ? props.width : '48rem')};
  }
  opacity: 1;
  transform: translateY(0);
`;

export const PopoverContent = styled.div`
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: white;
  padding: 20px 20px;
  
`

export const ArticleHeading = styled.h1`
  text-align: left;
  font-size: 40px;
  @media (max-width: 640px) {
    font-size: 28px !important;
  }
`
