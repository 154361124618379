import React, {useState, useRef, useLayoutEffect} from "react";
import {useNavigate} from "react-router-dom";

import monogram from "../imgs/monogram_black.png";
import mask from "../imgs/ellipscrew.png";

export default function Card(props) {
  const navigate = useNavigate();

  const {preview} = props

  const [hovering, setHovering] = useState(false);
  const [bodyRows, setBodyRows] = useState(5);

  const titleRef = useRef(null);

  const mobile = window.innerWidth < 900;

  useLayoutEffect(() => {
    const height = titleRef.current.getBoundingClientRect().height;
    if (height < 44) setBodyRows(6);
    else if (height < 88) setBodyRows(5);
    else if (height < 130) setBodyRows(3);
  }, []);

  return (
    <div
      id="jobcard"
      className="jobcard"
      title={props.text}
      meta={props.click}
      style={{
        display: "inline-block",
        position: "relative",
        width: 470,
        height: mobile ? 170 : 300,
        cursor: preview ? "cursor" : "pointer",
        zIndex: 10,
        borderRadius: 25,
        background: "#EDEDED",
        padding: "18px 33px",
        textAlign: "left",
        margin: 25,
        overflow: "hidden",
        transition: "150ms ease",
        transform: hovering ? "scale(1.02)" : "scale(1)",
      }}
      onMouseEnter={() => {
        setHovering(!preview);
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}
      onClick={() => {
        if (preview) return
        if (props.page) navigate(props.page);
        if (props.link) window.open(props.link);
      }}
    >
      {props.image && (
        <img
          alt="cardimg"
          src={props.image}
          style={{
            position: "absolute",
            height: "355px",
            top: "50%",
            left: 265,
            transform: "translateY(-50%)",
            WebkitMaskImage: "url(" + mask + ")",
            MaskImage: "url(" + mask + ")",
            WebkitMaskRepeat: "no-repeat",
            MaskRepeat: "no-repeat",
            WebkitMaskSize: "contain",
            MaskSize: "contain",
            overflow: "hidden",
          }}
        />
      )}

      {!props.image && (
        <div
          style={{
            position: "absolute",
            width: 200,
            height: 140,
            bottom: mobile ? -40 : 0,
            right: 0,
            background: props.red ? "#E512B5" : "#C1EF2C",
          }}
        />
      )}
      {!preview &&
        <h3
          style={{
            position: "absolute",
            bottom: mobile ? 16 : 33,
            left: props.image ? 34 : "unset",
            right: props.image ? "unset" : 18,
            padding: 0,
            margin: 0,
            fontSize: 17,
            textDecoration: "underline",
            color: props.image ? "black" : props.red ? "white" : "black",
          }}
        >
          {props.image ? "Read article" : "Learn More"}
        </h3>
      }
      <div
        className="jobcardtitle"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "50%",
          justifyContent: mobile ? "center" : "unset",
        }}
      >
        <h2
          ref={titleRef}
          style={{
            marginBottom: mobile ? 30 : 10,
            zIndex: 10,
            textAlign: "left",
            lineHeight: 1.4,
          }}
        >
          {props.text}
        </h2>
        <h3
          style={{
            display: mobile ? "none" : "-webkit-box",
            fontSize: 15,
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: bodyRows,
            LineClamp: bodyRows,
            WebkitBoxOrient: "vertical",
            margin: "15px 0px",
            height: bodyRows * 24,
            zIndex: 10,
          }}
        >
          {props.body}
        </h3>
      </div>

      {!props.image && (
        <div
          style={{
            position: "absolute",
            right: -60,
            top: mobile ? -191 : -110,
          }}
        >
          <img
            alt="monogramcard"
            src={monogram}
            style={{filter: "invert(1)", transform: "scale(1.3)"}}
          />
        </div>
      )}
    </div>
  );
}
