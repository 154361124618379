import React, {useContext, useEffect, useLayoutEffect, useState} from 'react'
import {Container, FullHeight, SpaceY, Title} from "../../../styles/global-style";
import {Input} from "../../../components/Admin/User/Account/style";
import {ImageCropperThumb} from "../../../components/ImageCropperThumb";
import {Theme} from "../../../utils/Theme";
import {Link, useNavigate, useParams} from "react-router-dom";
import Button from "../../../components/Button";

function AuthorsEdit() {
  const {setColor} = useContext(Theme);
  const navigate = useNavigate();
  const {idx} = useParams();

  const [authors, setAuthors] = useState({});
  const [profileUrl, setProfileUrl] = useState('')
  const [inputValues, setInputValues] = useState({
    fullName: '',
    jobTitle: '',
    linkedinUrl: '',
  });

  useLayoutEffect(() => {
    setColor("red");
    document.body.style =
      "background: conic-gradient(from -85deg at 50% 50%, #ccf029 0deg, #79e23a 64.86deg, #a4e931 176.24deg, #ccf029 360deg);";

    const getData = async () => { fetch("/.netlify/functions/getauthors", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        const _id = parseInt(idx, 10);
        const item = data.filter((item) => item.id === _id)
        setAuthors(...item);
      }); // Code logic here
    };

    getData()
  }, [setColor, idx]);


  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value
    }));
  };


  const addProfileImage = (header, content, published, thumbUrl) => {
    setProfileUrl(thumbUrl)
  }

  const editAuthor = () => {
    fetch("/.netlify/functions/editauthor", {
      method: "POST",
      body: JSON.stringify({
        id: idx,
        full_name: inputValues.fullName,
        job_title: inputValues.jobTitle,
        profile_url: profileUrl,
        linkedin_url: inputValues.linkedinUrl
      }),
    }).then(() => {
      navigate("/narnia/authors");
    })
  }


  useEffect(() => {
    if (authors) {
      setProfileUrl(authors.profile_url)

      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        fullName: authors.full_name,
        jobTitle: authors.job_title,
        linkedinUrl: authors.linkedin_url,
      }));
    }
  }, [authors])

  return (
    <FullHeight>
      <Container>
        <SpaceY number="1.5rem">
          <Link to="/narnia/authors" style={{
            color: "black",
            textDecoration: "none",
            alignItems: "center",
            display: "flex",
            marginBottom: "2rem",
            marginTop: "1rem"
          }}><i
            className="fas fa-arrow-left" style={{marginRight: ".5rem", fontSize: "18px"}}/>Back</Link>
          <Title>Edit author</Title>
          <div>
            <ImageCropperThumb
              editPost={addProfileImage}
              thumb={profileUrl ? profileUrl : authors?.profile_url}
              hideCardPreview
            />
          </div>
          <div
            style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <label>Fullname</label>
            <Input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={inputValues.fullName ? inputValues.fullName : authors?.full_name}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <label>Job Title</label>
            <Input
              type="text"
              name="jobTitle"
              placeholder="Head of Brand & Talent"
              value={inputValues.jobTitle ? inputValues.jobTitle : authors?.job_title}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{width: "100%", display: "flex", flexDirection: "column"}}>
            <label>Linkedin Url</label>
            <Input
              type="text"
              name="linkedinUrl"
              placeholder="https://www.linkedin.com/in/prolfile"
              value={inputValues.linkedinUrl ? inputValues.linkedinUrl : authors?.linkedin_url}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <Button text="Save Changes" black callback={editAuthor}/>
          </div>
        </SpaceY>
      </Container>
    </FullHeight>
  )
}


export default AuthorsEdit