import React, {useEffect, useContext} from "react";
import Button from "../components/Button";
import RecruitCards from "../components/RecruitCards";
import Section from "../components/Section";
import Ring from "../components/Ring";
import Investor from "../components/Home/Investor";

import {Theme} from "../utils/Theme";

import team from "../imgs/team.jpg";
import antler from "../imgs/antler.png";
import lvp from "../imgs/lvp.png";
import anthos from "../imgs/anthos.png";

import {
  HomeContainer,
  InvestorsText,
  InvestorsTextContainer,
  WaitingFor
} from "../components/Home/style";

import {FlexSpaceBetween, H3, Space} from "../styles/global-style";
import HeroSection from "../components/Home/HeroSection";
import HumbleSection from "../components/Home/HumbleSection";
import withTracker from "../hoc/withTracker";
import GameSection from "../components/Home/GameSection";


function Home() {
  const {setColor} = useContext(Theme);

  useEffect(() => {
    setColor("red");

    document.body.style =
      "background: conic-gradient(from -85deg at 50% 50%, #ccf029 0deg, #79e23a 64.86deg, #a4e931 176.24deg, #ccf029 360deg);";
  }, [setColor]);

  return (
    <HomeContainer>
      <Ring top={-254} right={-286}/>
      <GameSection/>
      <HeroSection/>
      <HumbleSection/>

      <Ring top={1800} left={-400} scale={1.4} rotate={-100}/>

      <Section img={team} text="From The Ground Up..." full>
        <H3 className="font-neue-haas" mt="-50px">
          A tried and tested founding team of three deep and orthogonal
          experiences. It's an immensly potential combo of humble team building,
          technical execution and creative excellence.
        </H3>
        <Button text="Leadership Values" click="/leadership" red large/>
      </Section>
      <Section top={120}>
        <h1>With A Unique Jetpack...</h1>
      </Section>
      <Space className="investors" margin="20px 100px">
        <FlexSpaceBetween className="investors-list">
          <Investor
            src={antler}
            url="http://www.antler.co"
            text="Antler"
          />
          <Investor
            src={lvp}
            url="http://www.londonvp.com"
            text="London Venture Partners"
          />
          <Investor
            src={anthos}
            url="https://anthoscapital.com/"
            text="Anthos Capital"
          />
        </FlexSpaceBetween>

        <InvestorsTextContainer>
          <InvestorsText className="font-neue-haas">
            We are immensely thankful and fortunate to be backed by the world's
            most prominent gaming investors. With their abyss-like well of
            experience added we are set up for making way more great decisions
            earlier.
          </InvestorsText>
        </InvestorsTextContainer>
      </Space>

      <Ring top={2200} right={-300} scale={0.7} rotate={100}/>

      <WaitingFor>
        <h1>So... What Are You Waiting For?</h1>
      </WaitingFor>

      <Ring top={4000} left={-600} scale={2.1} rotate={100}/>

      <Space mb="100px">
        <RecruitCards red isHome/>
      </Space>
    </HomeContainer>
  );
}


export default withTracker(Home)