import styled, {css} from 'styled-components';

export const Container = styled.div`
  max-width: 80rem;
  margin-inline: auto;
`

export const FullHeight = styled.div`
  min-height: calc(100vh - 120px);
  margin-bottom: 2rem;
`

export const SpaceY = styled.div`
  ${({number}) => `
   & > :not([hidden]) ~ :not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-top: calc(${number} * calc(1 - var(--tw-space-y-reverse)));
      margin-bottom: calc(${number} * var(--tw-space-y-reverse));
    }
  `}
`

export const SpaceX = styled.div`
  ${({number}) => `
   & > :not([hidden]) ~ :not([hidden]) {
      --space-y-reverse: 0;
       margin-left: calc(${number} * calc(1 - var(--space-y-reverse)));
       margin-right: calc(${number} * var(--space-y-reverse));
    }
  `}
`

export const Title = styled.h2`
  font-weight: 700;
  margin: 0;
`

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const FlexCenterCol = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const CustomLink = styled.a`
  color: ${props => props.color || 'black'};
  text-decoration: ${props => props.$underline ? 'underline' : 'none'};
  
  &:hover {
    text-decoration: ${props => props.$underline ? 'underline' : 'none'};
  }
`;

export const Author = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FullName = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: ${props => (props.$textWhite ? '#ffffff' : '#111827')};


  ${props => (props.$textWhite ?
          css`
            @media (max-width: 640px) {
              font-size: 18px;
            }` :
          css`@media (max-width: 640px) {
            font-size: 18px;
          }`)};


`;

export const JobTitle = styled.div`
  font-size: 16px;
  color: ${props => (props.$textWhite ? '#ffffff' : '#111827')};

  ${props => (props.$textWhite ?
          css`
            @media (max-width: 640px) {
              font-size: 14px;
            }` :
          css`@media (max-width: 640px) {
            font-size: 14px;
          }`)};

`

export const Published = styled.div`
  margin-left: 1rem;
  font-size: 14px;
  margin-top: .5rem;
  color: ${props => (props.$textWhite ? '#ffffff' : '#111827')};
`

export const Headline2 = styled.h2`
  text-align: ${props => props.textalign};
`

export const FlexFull = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const spacer = (props) => ({
  margin: props.margin,
  marginTop: props.mt,
  marginRight: props.mr,
  marginBottom: props.mb,
  marginLeft: props.ml,
  padding: props.padding,
  paddingTop: props.pt,
  paddingRight: props.pr,
  paddingBottom: props.pb,
  paddingLeft: props.pl,
  fontSize: props.fontSize,
  fontWeight: props.fontWeight,
});

export const H1 = styled.h1(spacer)
export const H2 = styled.h2(spacer)
export const H3 = styled.h3(spacer)
export const H4 = styled.h4(spacer)
export const Space = styled.div(spacer)

export const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & > :not([hidden]) ~ :not([hidden]) {
    --space-y-reverse: 0;
    margin-left: calc(2.5rem * calc(1 - var(--space-y-reverse)));
    margin-right: calc(2.5rem * var(--space-y-reverse));
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${props => (props.$left ? 'left' : 'right')};
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const BenefitsItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 1rem;
  .title-input{
    width: 100%;
  }
`;

export const SuccessMessage = styled.div`
  color: #000;
  font-weight: bold;
  padding: 30px 35px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-inline: 15px;
  background: #c1ef2c;
`;