import React from "react";
import {H1} from "../../styles/global-style";
import {Section, SectionGraphics, SectionText} from "./style";
import lebond from "../../imgs/LeBond.png";
import Button from "../Button";

const HumbleSection = () => {
  return (
    <Section>
      <SectionGraphics $imageLeft>
        <img src={lebond} alt="img"/>
      </SectionGraphics>
      <SectionText>
        <H1 mt="100px">Humble.</H1>
        <h1>Positive.</h1>
        <h1>Industry Drivers.</h1>
        <h3 className="font-neue-haas">
          The best idea usually hides in the mind of the most passionate and
          creative Modoyans sat silently in the back of the room.
        </h3>
        <Button text="The Place Of The Way" click="/culture" red large/>
      </SectionText>
    </Section>
  )
};


export default HumbleSection