import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useContext,
} from "react";
import { useParams, NavLink } from "react-router-dom";
import Section from "../components/Section";
import Button from "../components/Button";
import AttachButton from "../components/AttachButton";
import validator from "validator";
import { uploadFile } from "react-s3";
import { v4 as uid4 } from "uuid";
import Ring from "../components/Ring";
import { Theme } from "../utils/Theme";
import LoadSpinner from "../components/LoadSpinner";
import { TimelineMax, Power2 } from "gsap";

import sword from "../imgs/sword.png";
import swordland from "../imgs/swordland.png";
import helmet from "../imgs/helmet.png";
import diamond from "../imgs/diamond.png";
import outlinering from "../imgs/outlinering.png";
import shroom from "../imgs/shroom.png";
import shield from "../imgs/shield.png";
import withTracker from "../hoc/withTracker";
import {Helmet} from "react-helmet-async";
import {SuccessMessage} from "../styles/global-style";

// React-S3
window.Buffer = window.Buffer || require("buffer").Buffer;
var awsConfig = {};

function Job() {
  const { setColor } = useContext(Theme);
  const { id } = useParams();

  const swordRef = useRef();
  const swordlandRef = useRef();
  const firstHeaderRef = useRef();
  const helmetRef = useRef();
  const diamondRef = useRef();
  const outlineringRef = useRef();
  const shroomRef = useRef();
  const shieldRef = useRef();

  const [data, setData] = useState(null);
  const file = useRef(null);
  const [inputValid, setInputValid] = useState(false);
  const [applying, setApplying] = useState(false);
  const [error, setError] = useState(false);
  const [applySuccess, setApplySuccess] = useState(false);

  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const emailRef = useRef();
  const letterRef = useRef();

  const icons = [sword, helmet, diamond, outlinering, shroom, shield];
  const refs = [
    swordRef,
    helmetRef,
    diamondRef,
    outlineringRef,
    shroomRef,
    shieldRef,
  ];
  var iconIdx = 0;
  var switchSection = false;
  var continueBenefits = false;

  useLayoutEffect(() => {
    setColor("green");
    document.body.style = "background: #EDEDED;";

    fetch("/.netlify/functions/getaws", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((creds) => {
        awsConfig = creds;
      });

    if (id) {
      fetch("/.netlify/functions/getjob", {
        method: "POST",
        body: JSON.stringify({ id: id }),
      }).then(async (res) => {
        setData(parseData(await res.json()));
      });
    }
  }, [id, setColor]);

  useEffect(() => {
    if (data) {
      var tl = new TimelineMax();
      tl.set(swordRef.current, { y: -400, opacity: 0, rotate: 135 });
      tl.to(swordRef.current, 0.7, {
        y: 0,
        opacity: 1,
        ease: Power2.easeIn,
      })
        .set(swordRef.current, { opacity: 0 })
        .set(swordlandRef.current, { opacity: 1 })
        .fromTo(
          [swordlandRef.current, firstHeaderRef.current],
          0.01,
          { x: -3, y: -3 },
          { x: 3, y: 3, clearProps: "x,y", repeat: 20 }
        )
        .set(swordlandRef.current, { opacity: 0, delay: 1 })
        .set(swordRef.current, { opacity: 1 })
        .to(swordRef.current, 0.7, {
          rotate: 0,
          ease: Power2.easeOut,
        })
        .to(shroomRef.current, 0.3, {
          rotate: 359,
          scale: 1.4,
          ease: "easeNone",
        })
        .to(shroomRef.current, 0.3, {
          rotate: 720,
          scale: 1,
          ease: "easeNone",
        });
    }
  }, [data]);

  // Functions /////////////////////////////////

  function parseData(_data) {
    const _body = _data.data.attributes.body;
    const _arr = _body.split(">");
    var _arr2 = [];

    var type = "string";
    for (var i = 0; i < _arr.length; i++) {
      const str = _arr[i].trim();
      if (str.search("<ul") > -1) {
        type = "list";
      } else if (
        str.search("</ul") > -1 ||
        str.search("</h3") > -1 ||
        str.search("</h4") > -1 ||
        str.search("</em") > -1
      ) {
        type = "string";
      } else if (str.search("<h3") > -1) {
        type = "header";
      } else if (str.search("<h4") > -1) {
        type = "subheader";
      } else if (str.search("<em") > -1) {
        type = "bold";
      } else if (str.charAt(0) !== "<") {
        _arr2.push({ type: type, value: _arr[i].split("<")[0].trim() });
      }
    }

    return { title: _data.data.attributes.title, body: _arr2 };
  }

  function validate() {
    setInputValid(
      validator.isEmail(emailRef.current.value) &&
        firstnameRef.current.value.trim() &&
        lastnameRef.current.value.trim() &&
        file.current
    );
  }

  function apply() {
    if (emailRef.current && firstnameRef.current && lastnameRef.current) {
      setApplying(true);

      const _data = {
        email: emailRef.current.value,
        firstname: firstnameRef.current.value,
        lastname: lastnameRef.current.value,
      };

      fetch("/.netlify/functions/createcandidate", {
        method: "POST",
        body: JSON.stringify(_data),
      }).then(async (res) => {
        const resData = await res.json();
        const _data2 = {
          candId: resData.data.id,
          jobId: id,
          letter: letterRef.current.value,
        };

        fetch("/.netlify/functions/applyjob", {
          method: "POST",
          body: JSON.stringify(_data2),
        }).then(async (res2) => {
          if (file.current) {
            const filetype = file.current.name.split(".").pop();
            const newFilename =
              "CV-" +
              _data.firstname +
              "_" +
              _data.lastname +
              "-" +
              uid4().split("-").pop() +
              "." +
              filetype;
            const newFile = new File([file.current], newFilename, {
              type: file.current.type,
            });

            uploadFile(newFile, awsConfig)
              .then((uploadData) => {
                const _data3 = {
                  id: _data2.candId,
                  url: uploadData.location,
                  filename: uploadData.key,
                };

                fetch("/.netlify/functions/uploadfile", {
                  method: "POST",
                  body: JSON.stringify(_data3),
                }).then(async (res3) => {
                  setApplying(false);
                  setApplySuccess(true);

                  return true;
                });
              })
              .catch((err) => {
                console.error(err);
                setError(true);
              });
          } else {
            setApplying(false);
            setApplySuccess(true);

            return true;
          }
        });
      });
    }
  }

  function handleFileChange(e) {
    file.current = e ? e.target.files[0] : null;
    validate();
  }

  return (
    <>
      {data && data.title && (
        <Helmet>
          <title>{data.title}</title>
        </Helmet>
      )}
      <Ring top={-180} right={-286} white />
      <Ring top={880} left={-300} scale={1.3} white />
      <Ring top={2300} right={-100} scale={2} rotate={190} white />

      <div style={{ height: window.innerWidth < 900 ? 150 : 0 }} />

      <Section top={350} />
      {!data && <LoadSpinner />}
      <div
        className="jobDesc"
        style={{
          float: "left",
          marginLeft: 60,
          width: "54%",
        }}
      >
        <>
          {data && data.title && (
            <h1
              className="jobHeader"
              style={{ marginLeft: 66, marginBottom: 60 }}
            >
              {data.title}
            </h1>
          )}

          {data &&
            data.body &&
            data.body.map((val, i) => {
              if (switchSection) return <div key={"section1_0_" + i} />;
              var str = "";
              if (val.type === "header") {
                if (val.value === "Benefits") {
                  switchSection = true;
                  return <div key={"section1_0_" + i} />;
                }
                str = (
                  <div
                    className="jobSubheader"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      paddingLeft: 64,
                    }}
                  >
                    {icons[iconIdx] && (
                      <img
                        ref={refs[iconIdx]}
                        alt="icon"
                        src={icons[iconIdx]}
                        style={{
                          position: "absolute",
                          left: 0,
                          width: 46,
                          height: 46,
                          opacity: 1,
                        }}
                      />
                    )}
                    {i === 0 && (
                      <img
                        ref={swordlandRef}
                        alt="icon"
                        src={swordland}
                        style={{
                          position: "absolute",
                          left: 0,
                          width: 46,
                          height: 46,
                          opacity: 0,
                        }}
                      />
                    )}
                    {i === 0 ? (
                      <h2 ref={firstHeaderRef} style={{ fontWeight: "bold" }}>
                        {val.value}
                      </h2>
                    ) : (
                      <h2 style={{ fontWeight: "bold" }}>{val.value}</h2>
                    )}
                  </div>
                );
                if (icons[iconIdx]) iconIdx++;
              } else if (val.type === "subheader") str = <h5>{val.value}</h5>;
              else if (val.type === "string")
                str = (
                  <h3 className="font-neue-haas jobText" style={{ marginLeft: 66 }}>
                    {val.value}
                  </h3>
                );
              else if (val.type === "list")
                str = (
                  <div
                    className="jobBullet"
                    style={{
                      color: "black",
                      marginLeft: 20,
                    }}
                  >
                    <ul>
                      <li>
                        <h3 className="font-neue-haas">{val.value}</h3>
                      </li>
                    </ul>
                  </div>
                );

              return str ? (
                <div
                  className={val.type === "header" ? "jobSubheaderHolder" : ""}
                  key={"section1_" + i}
                >
                  {str}
                </div>
              ) : (
                <div key={"section1_1_" + i} />
              );
            })}
        </>
      </div>

      <div
        className="benefits"
        style={{
          position: "absolute",
          right: -30,
          width: "34%",
          background: "#C1EF2C",
          padding: "30px 0px",
          paddingLeft: 60,
          borderRadius: 30,
          marginBottom: 0,
        }}
      >
        {data &&
          data.body &&
          data.body.map((val, i) => {
            if (val.type === "header") {
              if (val.value === "Benefits") {
                continueBenefits = true;
              }
            }
            if (!continueBenefits) return <div key={"section2_0_" + i} />;

            var str = "";
            if (val.type === "header") {
              if (val.value === "Benefits") {
                continueBenefits = true;
              }
              str = (
                <div
                  className="jobSubheader"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {icons[iconIdx] && (
                    <img
                      alt="icon"
                      ref={refs[iconIdx]}
                      src={icons[iconIdx]}
                      style={{ width: 46, height: 46, marginRight: 20 }}
                    />
                  )}
                  <h2 style={{ fontWeight: "bold" }}>{val.value}</h2>
                </div>
              );
              if (icons[iconIdx]) iconIdx++;
            } else if (val.type === "subheader") str = <h5>{val.value}</h5>;
            else if (val.type === "string")
              str = <h3 className="font-neue-haas" style={{ marginLeft: 66 }}>{val.value}</h3>;
            else if (val.type === "list")
              str = (
                <div
                  className="jobBullet"
                  style={{
                    color: "black",
                    marginLeft: -23,
                    paddingRight: 60,
                  }}
                >
                  <ul>
                    <li>
                      <h3 className="font-neue-haas">{val.value}</h3>
                    </li>
                  </ul>
                </div>
              );

            return str ? (
              <div key={"section2" + i}>{str}</div>
            ) : (
              <div key={"section2_1_" + i} />
            );
          })}
      </div>

      <Section full>
        <h3>
          <b>
            We welcome applications from all backgrounds and encourage you to
            apply if this excites you!
          </b>
        </h3>
      </Section>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "0px auto",
        }}
      >
        {data && (
          <center className="form" style={{ margin: "0px auto", width: "80%" }}>
            <div
              style={{
                display: "block",
                maxWidth: "100%",
                marginBottom: 10,
              }}
            >
              {!applySuccess && (
                <>
                  <div className="formRow" style={{ display: "flex" }}>
                    <input
                      ref={firstnameRef}
                      className="input"
                      placeholder="First Name"
                      onChange={(e) => {
                        validate();
                      }}
                      disabled={applying}
                      style={{ flexGrow: 1 }}
                    />
                    <input
                      ref={lastnameRef}
                      className="input"
                      placeholder="Last Name"
                      onChange={(e) => {
                        validate();
                      }}
                      disabled={applying}
                      style={{ flexGrow: 1 }}
                    />
                  </div>
                  <div
                    className="formRow"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <input
                      ref={emailRef}
                      className="input"
                      placeholder="Email"
                      onChange={(e) => {
                        validate();
                      }}
                      disabled={applying}
                      style={{ flexGrow: 8, minWidth: 200 }}
                    />
                    <AttachButton
                      file={file.current}
                      handleFileChange={handleFileChange}
                    />
                  </div>
                  <textarea
                    ref={letterRef}
                    className="input"
                    placeholder="Tell us a little about you, feel free to include any links to your portfolio for us to take a look..."
                    style={{
                      fontFamily: "Termina",
                      textAlign: "left",
                      width: "93%",
                      height: 200,
                      padding: 25,
                    }}
                  />
                </>
              )}

              {applySuccess && (
                <SuccessMessage>
                  We have received your application and will get back to you shortly.
                  <br/>
                  THANK YOU!
                </SuccessMessage>
              )}
            </div>
            {!applySuccess && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: 15,
                  marginTop: -30,
                  opacity: !inputValid && !error ? 0.3 : 1,
                }}
              >
                {!applying && !error && (
                  <Button
                    text={"Apply For This Job"}
                    callback={() => {
                      apply();
                    }}
                    disabled={!inputValid}
                    large
                    green
                  />
                )}
                {error && (
                  <h3 style={{ color: "red" }}>
                    Oh noes! We're so sorry but something has epicly failed..
                    :´(
                    <br />
                    Please send us a mail instead to{" "}
                    <a href="mailto:hello@modoyo.com">hello@modoyo.com</a>
                  </h3>
                )}
              </div>
            )}

            <div
              style={{
                fontSize: 11,
                width: 600,
                color: "black",
                maxWidth: "90%",
                paddingTop: 20,
              }}
            >
              I have read the{" "}
              <NavLink to="/privacy" style={{ color: "black" }}>
                Privacy Policy
              </NavLink>{" "}
              and confirm that Modoyo store my personal details to be able to
              process my job application.
            </div>
          </center>
        )}
      </div>

      <Section text="Not Quite You?" full />

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button text="See other vacancies" click="/jobs" black large />
      </div>

      <div style={{ height: 100 }} />
    </>
  );
}

export default withTracker(Job)

