import React, {useState, useLayoutEffect} from "react";
import {useNavigate} from "react-router-dom";

import {ButtonBackground, ButtonContainer, ButtonInside} from "./style";
import Colors from "../../utils/colors";

export default function Button(props) {
  const {
    red,
    green,
    white,
    whiteBg,
    lightBlue,
    black,
    disabled,
    menuitem,
    large,
    link,
    click,
    callback,
    invert,
    text
  } = props
  const navigate = useNavigate();

  const [hovering, setHovering] = useState(false);
  const [down, setDown] = useState(false);
  const [background, setBackground] = useState(null);
  const [color, setColor] = useState(null);

  useLayoutEffect(() => {
    if (red) {
      setBackground(Colors.CAL);
      setColor("#fff");
    } else if (green) {
      setBackground("#c1ef2c");
      setColor("#000");
    } else if (black) {
      setBackground("black");
      setColor("#fff");
    } else if (white) {
      setColor("#fff");
    } else if (lightBlue) {
      setBackground("#29BEF0");
      setColor("#000");
    } else if (whiteBg) {
      setBackground("#fff");
      setColor("black");
    }
    else {
      setColor("none");
      setBackground("none");
    }
  }, [red, green, white, black, whiteBg, lightBlue]);


  const handleMouseEnter = () => {
    if (disabled) return;
    setHovering(true);
  }

  const handleMouseLeave = () => {
    if (disabled) return;
    setDown(false);
    setHovering(false);
  }

  const handleMouseUp = () => {
    if (disabled) return;
    if (link) window.open(link);
    if (click) navigate(click);
    if (callback) callback();
  }

  const handleMouseDown = () => {
    if (disabled) return;
    setDown(true);
  }


  return (
    <ButtonContainer
      className="menuitem"
      $disabled={disabled}
      $down={down}
      $menuitem={menuitem}
      $large={large}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
    >
      <ButtonBackground
        $hovering={hovering}
        $background={background}
        $large={large}
      />
      <ButtonInside
        $large={large}
        $color={color}
        $disabled={disabled}
        $hovering={hovering}
        $invert={invert}
      >
        {text && text}
      </ButtonInside>
    </ButtonContainer>
  );
}
