import React, {useContext} from "react";
import {Routes, Route, Navigate, Outlet, useLocation} from "react-router-dom";
import {Provider} from "./utils/Theme";
import {AuthContext} from "./utils/AuthContext";

// Components
import Menu from "./components/Menu";
import AdminNavigation from "./components/Admin/Navigation";
import Footer from "./components/Footer";

// Pages
import Home from "./pages/Home";
import Press from "./pages/Press";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ToS from "./pages/ToS";
import Culture from "./pages/Culture";
import Leadership from "./pages/Leadership";
import Job from "./pages/Job";
import Jobs from "./pages/Jobs";
import Team from "./pages/Team";
import Game from "./pages/Game";
import News from "./pages/News";
import NewsArticle from "./pages/NewsArticle";
import Login from "./pages/Login";
import AdminNews from "./pages/Admin/News/index";
import AdminNewsEdit from "./pages/Admin/News/Edit";
import UserAccount from "./pages/Admin/User/Account";
import Authors from "./pages/Admin/Authors";
import AuthorsNew from "./pages/Admin/Authors/New";
import AuthorsEdit from "./pages/Admin/Authors/Edit";
import AdminTeam from "./pages/Admin/Team";
import TeamNew from "./pages/Admin/Team/New";
import TeamEdit from "./pages/Admin/Team/Edit";
import JobEdit from "./pages/Admin/Job/Edit";
import {HelmetProvider} from "react-helmet-async";
import GameEdit from "./pages/Admin/Game/Edit";

function App() {
  return (
    <HelmetProvider>
      <Provider color="red">
        <Routes>
          <Route element={<Root/>}>
            <Route path="/" element={<Home/>}/>
            <Route path="/press" element={<Press title="Press"/>}/>
            <Route path="/privacy" element={<PrivacyPolicy title="Privacy Policy"/>}/>
            <Route path="/tos" element={<ToS title="Terms and Conditions"/>}/>
            <Route path="/culture" element={<Culture title="Our Culture"/>}/>
            <Route path="/leadership" element={<Leadership title="Leadership"/>}/>
            <Route path="/jobs" element={<Jobs title="Join Us"/>}/>
            <Route path="/job/:id/:title" element={<Job/>}/>
            <Route path="/team" element={<Team title="The Team"/>} />
            <Route path="/playtest" element={<Game title="Game"/>}/>
            <Route path="/news" element={<News title="News"/>}/>
            <Route path="/news/:id" element={<NewsArticle/>}/>
            <Route path="login" element={<Login title="Login"/>}/>
          </Route>
          <Route path="/narnia" element={<Admin/>}>
            <Route index element={<AdminNews/>}/>
            <Route path="news/edit/:idx" element={<AdminNewsEdit/>}/>
            <Route path="account" element={<UserAccount/>}/>
            <Route index path="authors" element={<Authors/>}/>
            <Route path="authors/new" element={<AuthorsNew/>}/>
            <Route path="authors/edit/:idx" element={<AuthorsEdit/>}/>
            <Route index path="team" element={<AdminTeam/>}/>
            <Route path="team/new" element={<TeamNew/>}/>
            <Route path="team/edit/:idx" element={<TeamEdit/>}/>
            <Route path="job/edit" element={<JobEdit/>}/>
            <Route path="game/edit" element={<GameEdit/>}/>
          </Route>
          <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
      </Provider>
    </HelmetProvider>
  );
}

function Root() {
  const {user} = useContext(AuthContext);
  if (user) return <Navigate to="/narnia"/>;
  else
    return (
      <>
        <Menu/>
        <div className="container">
          <Outlet/>
        </div>
        <Footer/>
      </>
    );
}

function Admin() {
  const {user} = useContext(AuthContext);
  let location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{from: location}} replace/>;
  }

  return (
    <>
      <AdminNavigation/>
      <Outlet/>
    </>
  );
}

export default App;
