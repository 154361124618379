import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import Card from "./Card";
import Button from "../components/Button";
import Colors from "../utils/colors";
import {NavLink} from "react-router-dom";
import validator from "validator";
import Lottie from "react-lottie";
import AttachButton from "./AttachButton";
import {uploadFile} from "react-s3";
import {v4 as uid4} from "uuid";

import loader from "../lotties/loader";
import {
  IconContainer,
  ListboxButton,
  ListBoxContainer,
  ListboxOption,
  ListboxOptions, SelectedIcon,
  Truncate, TruncatedText
} from "./Admin/News/style";
import {Listbox} from "@headlessui/react";
import {Space} from "../styles/global-style";
import {transformTitle} from "../utils/common";

// React-S3
window.Buffer = window.Buffer || require("buffer").Buffer;
var awsConfig = {};

export default function RecruitCards(props) {
  const {
    isHome = false
  } = props

  const [jobs, setJobs] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState({})
  const [showConnect, setShowConnect] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [applying, setApplying] = useState(false);
  const [applySuccess, setApplySuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);

  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const emailRef = useRef();
  const letterRef = useRef();


  useLayoutEffect(() => {
    fetch("/.netlify/functions/getaws", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((creds) => {
        awsConfig = creds;
      });

    fetch("/.netlify/functions/getjobs", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        var _jobs = [];
        for (var x = 0; x < data.data.length; x++) {
          const _body = data.data[x].attributes.body;
          const _arr = _body.split(">");
          var resStr = "";

          for (var i = 0; i < _arr.length; i++) {
            const str = _arr[i].trim();
            if (
              str.charAt(0) !== "<" &&
              _arr[i].split("<")[0].trim() !== "About the role"
            ) {
              resStr += " " + _arr[i].split("<")[0].trim();
            }
          }

          _jobs.push({
            id: data.data[x].id,
            title: data.data[x].attributes.title,
            body: resStr,
          });
        }

        setJobs(_jobs);
        setLoading(false);
      });
  }, []);

  const validate = useCallback(() => {

    if (emailRef.current) {
        setInputValid(
            validator.isEmail(emailRef.current.value) &&
            firstnameRef.current.value.trim() &&
            lastnameRef.current.value.trim() &&
            Object.keys(selectedDepartment).length > 0
        )
    }
  }, [selectedDepartment]);

  useEffect(() => {
    validate();
  }, [selectedDepartment, validate]);

  function getDepartments() {
    fetch("/.netlify/functions/getdepartments", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setDepartments(data.data);
      });
  }

  function changeDepartment(value) {
    setSelectedDepartment(value);
  }

  function removeDepartment (){
    setSelectedDepartment({})
  }

  function apply() {
    if (emailRef.current && firstnameRef.current && lastnameRef.current) {
      setApplying(true);

      const _data = {
        email: emailRef.current.value,
        firstname: firstnameRef.current.value,
        lastname: lastnameRef.current.value,
        letter: letterRef.current.value,
        department: selectedDepartment.id,
      };

      fetch("/.netlify/functions/createconnect", {
        method: "POST",
        body: JSON.stringify(_data),
      }).then(async (res) => {
        if (file) {
          const resData = await res.json();

          const filetype = file.name.split(".").pop();
          const newFilename =
            "CV-" +
            _data.firstname +
            "_" +
            _data.lastname +
            "-" +
            uid4().split("-").pop() +
            "." +
            filetype;
          const newFile = new File([file], newFilename, {
            type: file.type,
          });

          uploadFile(newFile, awsConfig)
            .then((uploadData) => {
              const _data3 = {
                id: resData.data.id,
                url: uploadData.location,
                filename: uploadData.key,
              };

              fetch("/.netlify/functions/uploadfile", {
                method: "POST",
                body: JSON.stringify(_data3),
              }).then(async () => {
                setApplying(false);
                setApplySuccess(true);

                return true;
              });
            })
            .catch((err) => console.error(err));
        } else {
          setApplying(false);
          setApplySuccess(true);

          return true;
        }
      });
    }
  }

  function handleFileChange(e) {
    setFile(e ? e.target.files[0] : null);
  }

  return (
    <center
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "20px 0px",
        width: "100%",
      }}
    >
      {loading && (
        <Lottie options={{animationData: loader}} style={{height: 200}}/>
      )}

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {jobs &&
          jobs.map((val, i) => {
            return (
              <Card
                key={"card" + i}
                text={val.title}
                body={val.body}
                size={props.size}
                page={`/job/${val.id}/${transformTitle(val.title)}`}
                tag="open"
                jobid={val.id}
                red={props.red}
              />
            );
          })}
      </div>
      {!showConnect && (
        <>
          <div style={{display: "block", marginTop: 30}}>
            {isHome ?
              <Button
                text="Join our team"
                green={!props.red}
                red={props.red}
                click="/jobs"
                large
              />
              :
              <Button
                text="Send An Open Application"
                green={!props.red}
                red={props.red}
                callback={() => {
                  setShowConnect(true);
                  getDepartments();
                }}
                large
              />
            }
          </div>
        </>
      )}
      {showConnect && (
        <center
          className="form"
          style={{width: "80%", margin: "100px auto", marginBottom: -70}}
        >
          <h1
            style={{marginBottom: 40, color: props.red ? "black" : "white"}}
          >
            Connect With Us!
          </h1>
          <h3 style={{color: props.red ? "black" : "white"}}>
            Say hi and feel free to share your portfolio with us
          </h3>
          <div
            style={{
              display: "block",
              maxWidth: "100%",
              marginBottom: 10,
            }}
          >
            <div className="formRow" style={{display: "flex"}}>
              <input
                ref={firstnameRef}
                className="input"
                placeholder="First Name"
                onChange={() => {
                  validate();
                }}
                disabled={applying}
                style={{
                  flexGrow: 1,
                  background: props.red ? "#a9db2b" : "#ffffffaa",
                }}
              />
              <input
                ref={lastnameRef}
                className="input"
                placeholder="Last Name"
                onChange={() => {
                  validate();
                }}
                disabled={applying}
                style={{
                  flexGrow: 1,
                  background: props.red ? "#a9db2b" : "#ffffffaa",
                }}
              />
            </div>
            <div className="formRow" style={{display: "flex", width: "100%"}}>
              <input
                ref={emailRef}
                className="input"
                placeholder="Email"
                onChange={() => {
                  validate();
                }}
                disabled={applying}
                style={{
                  flexGrow: 8,
                  minWidth: 200,
                  background: props.red ? "#a9db2b" : "#ffffffaa",
                }}
              />
              <AttachButton
                file={file}
                handleFileChange={handleFileChange}
                red={props.red}
              />
            </div>
            <textarea
              ref={letterRef}
              className="input"
              placeholder="Tell us a little about you, feel free to include any links to your portfolio for us to take a look..."
              style={{
                fontFamily: "Termina",
                textAlign: "left",
                width: "93%",
                height: 200,
                padding: 25,
                background: props.red ? "#a9db2b" : "#ffffffaa",
                backdropFilter: "blur(10px)",
              }}
            />

            <Space margin="0 15px">
              <Listbox by="id" value={selectedDepartment} onChange={changeDepartment}>
                <ListBoxContainer>
                  <ListboxButton $second>
                    <Truncate>
                      {selectedDepartment && selectedDepartment.attributes && selectedDepartment.attributes.name ?
                          <Truncate $flex>
                            <Truncate style={{flex: 1, marginRight: "1rem"}}>
                              {selectedDepartment?.attributes.name}
                            </Truncate>
                            <span
                                style={{
                                  background: "black",
                                  color: "white",
                                  borderRadius: "100%",
                                  width: 27,
                                  height: 27,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: 12,
                                  marginRight: "2rem"
                                }}
                                onClick={removeDepartment}
                            >
                          <i className="fas fa-times"/>
                        </span>
                          </Truncate>
                          :
                          'Select Department'
                      }
                    </Truncate>
                    <IconContainer>
                      <i className="fas fa-chevron-down"/>
                    </IconContainer>
                  </ListboxButton>

                  <ListboxOptions $second>
                    {departments &&
                        departments.map((department, index) => (
                            <ListboxOption
                                key={index}
                                value={department}
                                $second
                            >
                              {({selected}) => (
                                  <>
                                    <TruncatedText selected={selected}>
                                      {department.attributes.name}
                                    </TruncatedText>
                                    {selected ? (
                                        <SelectedIcon $second>
                                          <i className="fas fa-check"/>
                                        </SelectedIcon>
                                    ) : null}
                                  </>
                              )}
                            </ListboxOption>
                        ))}
                  </ListboxOptions>
                </ListBoxContainer>
              </Listbox>
            </Space>

            {applySuccess && (
              <div
                style={{
                  color: props.red ? "black" : "white",
                  fontWeight: "bold",
                  padding: "30px 35px",
                  marginTop: 40,
                  marginBottom: 40,
                  width: "94%",
                  border: "3px solid " + Colors.CAL,
                }}
              >
                Connection made, THANK YOU!
              </div>
            )}
          </div>

          {!applySuccess && (
            <div style={{marginTop: 50}}>
              <Button
                text={applying ? "" : "Connect With Us"}
                callback={() => {
                  apply();
                }}
                disabled={!inputValid || applying}
                large
                red={props.red}
                green={!props.red}
              />
            </div>
          )}

          <div
            style={{
              fontSize: 11,
              padding: 30,
              width: 600,
              maxWidth: "80%",
              color: props.red ? "black" : "white",
            }}
          >
            I have read the{" "}
            <NavLink
              to="/privacy"
              style={{color: props.red ? "black" : "white"}}
            >
              Privacy Policy
            </NavLink>{" "}
            and confirm that Modoyo store my personal details to be able to
            process my job application.
          </div>
        </center>
      )}
    </center>
  );
}
