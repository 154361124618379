import styled from "styled-components";

export const MemberContainer = styled.div`
  margin-top: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    margin-top: 0
  }
`

export const MemberItemCard = styled.div`
  width: ${props => props.size}px;
  background-color: rgb(255 255 255 / 40%);
  border-radius: 25px;
  padding: 10px 30px 35px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);


  @supports (-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)) {
    background-color: rgb(255 255 255 / 40%);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  @media (max-width: 900px) {
    margin: 0 20px;
  }
  
`;

export const OuterContainer = styled.div`
  position: relative;
  height: ${({ size }) => size + 40}px;
  overflow: hidden;
  margin-bottom: -60px;
`;

export const InnerCircle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  width: ${({ size }) => size - 80}px;
  height: ${({ size }) => size - 80}px;
  border-radius: 50%;
  z-index: 1;
`;

export const MaskedImageContainer = styled.div`
  -webkit-mask-image: url(${({ ellipse2 }) => ellipse2});
  mask-image: url(${({ ellipse2 }) => ellipse2});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  overflow: hidden;
`;

export const HiddenImage = styled.div`
  position: absolute;
  transition: 500ms ease;
  opacity: ${({ $hovering }) => ($hovering ? 0 : 1)};
`;

export const Img = styled.img`
  width: 100%;
`;

export const TeamMemberImg = styled.div`
  & img {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  line-height: 1.3;
  text-align: center;
  margin-bottom: 20px;
`

